import React from 'react';
import { useModalPopupContext } from '../../context/ModalPopupContext';
import { ScreenBusUtils } from '../../hooks/useEventBus';
import { SegmentBusTrackTruID } from '../../segment-bus/SegmentBusTrackTruID';

import { TruidLeaveChangeAddress } from './TruidConfirmChangeAddress';

export const useTruIDConfirmChange = () => {
    const {
        showModal,
    } = useModalPopupContext();

    const showConfirmLeaveChange = ({
        dateExpired,
        handleOK,
        isSalaryDate,
        ...props
    } = {}) => {
        if (!isSalaryDate) {
            SegmentBusTrackTruID.segment1500MissingAddressPopupData();
        }

        showModal({
            modalContent: <TruidLeaveChangeAddress
                dateExpired={dateExpired}
                isSalaryDate={isSalaryDate}
            />,
            showXClose: false,
            centerAction: true,
            escOnClose: false,
            cancelTitle: "I'll do it later",
            okTitle: 'Share missing information',
            handleOK,
            handleCancel: ScreenBusUtils.goHome,
            ...props,
        });
    };

    return showConfirmLeaveChange;
};

export default useTruIDConfirmChange;
