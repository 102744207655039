import moment from 'moment';

import { combineEpics, ofType } from 'redux-observable';
import { of, timer } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, takeUntil } from 'rxjs/operators';

import _ from 'lodash';
import {
    verifyOtpSuccess,
    handleErrorApi,
    generateOtpSucess,
    saveStepUpToken,
    saveCustomerInfo,
    evaluateEligibilityCheckResultWithRetry,
    verifyOtpFailed,
    verifyLoanApplicationSuccess,
    saveStepsInput,
    fetchUserProfileType,
    fetchUserProfileTypeSuccess,
} from '../actions';

import { ErrorCode, profileEFType, routeName } from '../constants';
import Config from '../config';
import ErrorUtils from '../utils/errorUtils';
import { handleDeclineSpecialCode } from '../utils/commonUtils';
import { ScreenBusUtils, TruIDBusUtils } from '../hooks/useEventBus';
import { SegmentBusTrackOtp } from '../segment-bus/SegmentBusTrackOtp';

import {
    EVALUATE_ELIBILITY_CHECK_IN_PROGRESS,
    EVALUATE_ELIBILITY_CHECK_RESULT,
    EVALUATE_ELIBILITY_CHECK_RESULT_WITH_RETRY,
    FETCH_USER_TYPE,
    GENERATE_OTP,
    VERIFY_LOAN_APPLICATION_IN_ELIBILITY,
    VERIFY_OTP,
    VERIFY_OTP_SUCCESS,
} from '../store/actionTypes';
import apiService from '../services/apiService';

export const saveProfileType = (results, store$) => {
    const { userInfo } = store$.value.stepsInputData;
    const {
        isReceivedSalaryInTyme,
        email,
        profileType,
        raceCode,
        employmentStatus,
        companyName,
        employmentStartDate,
        lastLoanEmail,
    } = results || {};

    let workingMonth, workingYear;

    if (employmentStartDate) {
        const startedWorkingDate = moment(new Date(employmentStartDate)).format('MM-YYYY');
        const yearMonth = startedWorkingDate.split('-');
        workingMonth = yearMonth[0];
        workingYear = yearMonth[1];
    }

    // tracking error
    if (raceCode) {
        SegmentBusTrackOtp.segment500LoanPuplateDataDisplayedData();
    }

    return saveStepsInput({
        userInfo: {
            ...userInfo,
            emailValue: lastLoanEmail || email,
            isReceivedSalaryInTyme,
            isEFProfile: profileEFType.includes(profileType),
            selectedEmploymentStatus: employmentStatus,
            company: companyName,
            workingMonth,
            workingYear,
            selectedRace: raceCode,
        },
    });
};
export const verifyLoanApplicationInElibilityEpic = (action$, store$) =>
    action$.pipe(
        ofType(VERIFY_LOAN_APPLICATION_IN_ELIBILITY),
        switchMap(({ payload }) => {
            const { loanApplicationNumber } = payload;
            ScreenBusUtils.showLoading();

            return apiService.getAffordabilityResults(loanApplicationNumber)
                .pipe(
                    switchMap(res => {
                        ScreenBusUtils.hideLoading();
                        const { loanApplicationStatus, upsellStatus } = res || {};

                        const isEFProfile = profileEFType.includes(res.profileType);

                        const declineReasonDetails = res.declineReasonDetails || {};
                        if (declineReasonDetails.code) {
                            const funcSpecial = handleDeclineSpecialCode({
                                declinceCode: declineReasonDetails.code,
                                handleMissing: () => ScreenBusUtils.saveDeclineStatus(declineReasonDetails.code),
                                handleGProfileType: () => ScreenBusUtils.showModalTruIDGProfileType(declineReasonDetails),
                            });

                            if (!funcSpecial) {
                                TruIDBusUtils.notifyResume({
                                    ...declineReasonDetails,
                                    loanApplicationStatus,
                                    loanApplicationNumber,
                                });
                                ScreenBusUtils.saveDeclineStatus(declineReasonDetails.code, declineReasonDetails);

                                return of(
                                    saveProfileType(res, store$),
                                    verifyLoanApplicationSuccess({
                                        loanApplicationNumber,
                                        loanApplicationStatus,
                                        isEFProfile,
                                        declinceCode: declineReasonDetails.code,
                                    }),
                                    ScreenBusUtils.redirectScreenAfterResumeByLoanStatus({
                                        loanApplicationStatus,
                                        isEFProfile,
                                        loanApplicationNumber,
                                        upsellStatus,
                                        declinceCode: declineReasonDetails.code,
                                    }),
                                );
                            }
                            funcSpecial();
                        }

                        return of(
                            saveProfileType(res, store$),
                            verifyLoanApplicationSuccess({
                                loanApplicationNumber,
                                loanApplicationStatus,
                                isEFProfile,
                            }),
                            ScreenBusUtils.redirectScreenAfterResumeByLoanStatus({
                                loanApplicationStatus,
                                isEFProfile,
                                loanApplicationNumber,
                                upsellStatus,
                            }),
                        );
                    }),
                    catchError(error => ErrorUtils.getApiAction(error)),
                );
        }));

export function verifyOtp(action$) {
    return action$.pipe(
        ofType(VERIFY_OTP),
        switchMap(({ payload }) => {
            const { requestToken, otpCode } = payload;
            return (
                apiService
                    .verifyOtp(requestToken, otpCode)
                    .pipe(
                        map(verifyOtpSuccess),
                        catchError(error => {
                            if (!error.response) {
                                return of(handleErrorApi(([{ errorCode: ErrorCode.CONNECTIVITY_ERROR }])));
                            }
                            return of(verifyOtpFailed(ErrorUtils.parseError({ error })[0]));
                        }),
                    )
            );
        }));
}

export function verifyOtpSuccessEpic(action$, store) {
    return action$.pipe(
        ofType(VERIFY_OTP_SUCCESS),
        switchMap(action => {
            const { payload } = action;
            const {
                isVerifyLoanSuccess,
                loanApplicationStatus,
                loanApplicationNumber,
                upsellStatus,
                declinceCode,
            } = store.value.verifyLoanApplicationData;

            // fetching here (smooth screen)
            TruIDBusUtils.fetchSAAdministrative();

            if (isVerifyLoanSuccess && loanApplicationStatus) {
                const { userInfo } = store.value.stepsInputData;
                return of(
                    saveStepUpToken(payload.stepUpToken),
                    ScreenBusUtils.redirectScreenAfterResumeByLoanStatus({
                        loanApplicationStatus,
                        isEFProfile: userInfo.isEFProfile,
                        loanApplicationNumber,
                        upsellStatus,
                        declinceCode,
                    }),
                );
            }

            // evaluate eligibility check result
            const request = {
                stepUpToken: payload.stepUpToken,
                said: _.get(store.value, 'said.customer.saId'),
            };
            return of(saveStepUpToken(payload.stepUpToken), evaluateEligibilityCheckResultWithRetry(request));
        }));
}

// FOR COVER UT
export const evaluateEligibilityCheckResultInRetryAgain = (payload, state$) => {
    const { stepUpToken, said, makeDecision } = payload;
    return apiService.evaluateEligibilityCheckResult(stepUpToken, said, makeDecision)
        .pipe(
            switchMap(res => {
                const {
                    requesting, eligible, resumableLoanApplicationNumber, plProductId, hashedSAID,
                } = res;

                plProductId && ScreenBusUtils.changeSegmentProduct(plProductId);

                // exist loanApplicationNumber
                if (resumableLoanApplicationNumber) {
                    ScreenBusUtils.sendGAPage(routeName.OTP_RESUME);
                    SegmentBusTrackOtp.segment500OtpResumeCheckScreenDisplayedData();

                    return of(
                        verifyLoanApplicationSuccess({ loanApplicationNumber: resumableLoanApplicationNumber, hashedSAID }),
                        handleErrorApi([{ errorCode: ErrorCode.EXISTING_INVITATION }, resumableLoanApplicationNumber]),
                    );
                }

                if (eligible || state$.value.eligibilityCheckResults.retryCount >= 4) {
                    return of(
                        saveStepsInput({ hashedSAID }),
                        saveCustomerInfo(res),
                        fetchUserProfileType({ stepUpToken, said }),
                    );
                }

                // eligibility
                if (requesting) {
                    return of({ type: EVALUATE_ELIBILITY_CHECK_IN_PROGRESS });
                }

                return of(handleErrorApi([{ errorCode: ErrorCode.GENERIC_ERROR }]));
            }),
            catchError(error => ErrorUtils.getApiAction(error)),
        );
};

export function evaluateEligibilityCheckResultWithRetryEpic(action$, state$) {
    return action$.pipe(
        ofType(EVALUATE_ELIBILITY_CHECK_RESULT_WITH_RETRY),
        switchMap(({ payload }) => {
            const { makeDecision } = payload;
            return timer(0, Config.decisioning.requestFrequentSpan)
                .pipe(
                    takeUntil(action$.pipe(filter(action => action.type !== EVALUATE_ELIBILITY_CHECK_IN_PROGRESS && !makeDecision))),
                    exhaustMap(() => evaluateEligibilityCheckResultInRetryAgain(payload, state$)),
                );
        }));
}

export const evaluateEligibilityCheckResultEpic = (action$) =>
    action$.pipe(
        ofType(EVALUATE_ELIBILITY_CHECK_RESULT),
        switchMap(({ payload }) => {
            const { stepUpToken, said, makeDecision } = payload;
            return apiService.evaluateEligibilityCheckResult(stepUpToken, said, makeDecision)
                .pipe(
                    switchMap(res => {
                        const { eligible } = res;
                        if (eligible) {
                            return of(saveCustomerInfo(res));
                        }
                        return of(handleErrorApi([{ errorCode: ErrorCode.GENERIC_ERROR }]));
                    }),
                    catchError(error => ErrorUtils.getApiAction(error)),
                );
        }));

export function generateOtpEpic(action$) {
    return action$.pipe(
        ofType(GENERATE_OTP),
        switchMap(({ payload }) => {
            const { requestToken } = payload;
            return (
                apiService
                    .generateOtp(requestToken)
                    .pipe(
                        map(generateOtpSucess),
                        catchError(error => ErrorUtils.getApiAction(error)),
                    )
            );
        }));
}

export function fetchUserProfileTypeEpic(action$, store$) {
    return action$.pipe(
        ofType(FETCH_USER_TYPE),
        switchMap(({ payload }) => {
            const { stepUpToken, said } = payload;

            return apiService
                .getCustomerInformation(stepUpToken, said)
                .pipe(
                    switchMap(res =>
                        of(
                            saveProfileType(res, store$),
                            fetchUserProfileTypeSuccess(),
                            ScreenBusUtils.gotoScreenLoanAmount(),
                        ),
                    ),
                    catchError(error => ErrorUtils.getApiAction(error)),
                );
        }));
}

export default combineEpics(
    verifyOtp,
    verifyOtpSuccessEpic,
    generateOtpEpic,
    evaluateEligibilityCheckResultEpic,
    evaluateEligibilityCheckResultWithRetryEpic,
    verifyLoanApplicationInElibilityEpic,
    fetchUserProfileTypeEpic,
);

