import { canEditAddressProvince } from '../../toogle';
import truidProvinceData from './assets/truid-province-data.json';

export function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function upperFirst(str) {
    return str.split(' ').map(capitalize).join(' ');
}

export function parseSuburbSuggestions(administrative) {
    return (administrative).map((item) => ({
        ...item,
        suburb: upperFirst(item.S),
        city: upperFirst(item.C),
        province: upperFirst(item.P),
        zipCode: `${item.PO}`.trim(),
    }));
}

export const parseCitySugestions = administrative => {
    const item = parseSuburbSuggestions(administrative);
    const parseContext = ({ city, province }) => `${city}-${province}`;
    const ids = item.map(parseContext);
    return item.filter((e, index) => !ids.includes(parseContext(e), index + 1));
};

export const isStartsWithSelection = (value, text) =>
    `${value}`.toLowerCase().startsWith(`${text}`.toLowerCase());

export const isEqualSelection = (value, text) =>
    `${value}`.toLowerCase().trim() === `${text}`.toLowerCase().trim();

export const isContainSelection = (value, text) =>
    `${value}`.toLowerCase().includes(`${text}`.toLowerCase());

export const findCityItem = (cityName, suggestions) => {
    const item = suggestions.find(c => isEqualSelection(c.city, cityName)) || {};
    return item.city;
};

// KwaZulu-Natal
export const findProvinceItem = provinceName => {
    if (`${provinceName}`.toLowerCase() === 'kwazulu-natal') {
        return 'Kwa-Zulu Natal';
    }
    return truidProvinceData.find(p => isEqualSelection(p, provinceName));
};

export const findCityBySuburb = ({ suburb, suburbSugestions, administrativeData }) => {
    const results = suburbSugestions.filter(data => isStartsWithSelection(data.S, suburb));
    return parseCitySugestions(results, administrativeData);
};

export const canSearchText = (value = '', length = 1) => value.trim().length > length;

export const validateAddress = ({
    address = '',
    city = '',
    zipCode = '',
    suburb = '',
    province = '',
}) => {
    const isErrorAddress = !address.trim();
    const isErrorCity = !city.trim();
    const isErrorProvince = canEditAddressProvince() && !province.trim();
    const isErrorSuburb = !suburb.trim();
    const isErrorZipCode = !zipCode;
    const isOverZipCode = !(zipCode > 0 && zipCode < 10000);

    return {
        isErrorAddress,
        isErrorSuburb,
        isErrorCity,
        isErrorZipCode: isErrorZipCode || isOverZipCode,
        isErrorProvince,
        msgZipCode: isErrorZipCode ? 'Enter a valid postal code' : 'Postal code must be from 0001 to 9999',
        isErrorAny: isErrorAddress || isErrorCity || isErrorProvince || isErrorSuburb || isErrorZipCode || isOverZipCode,
    };
};
