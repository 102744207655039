import React from 'react';

import PLButton from '../Common/PLButton';
import { WrapAction } from '../Common/ActionButtons';
import WrapperIconModal from '../Common/WrapperIconModal';
import { ScreenBusUtils } from '../../hooks/useEventBus';
import { canEditAddressProvince } from '../../toogle';

import { SegmentBusTrackTruID } from '../../segment-bus/SegmentBusTrackTruID';

import { useSaveAddress } from './useHandleTruidAddress';
import { TruidWrapDialogContent } from './TruidWrapperModal';

import HomeAddressIcon from '../../assets/images/elements/HomeAddressIcon';
import MailIcon from '../../assets/images/elements/MailIcon';

import DocumentIcon, { DocumentQuestionIcon } from '../../assets/images/elements/DocumentIcon';
import LocationIcon from '../../assets/images/elements/LocationIcon';

export const TruidLeaveChangeAddress = ({ dateExpired, isSalaryDate }) => (
    <TruidWrapDialogContent
        icon={isSalaryDate ? <DocumentQuestionIcon /> : <LocationIcon />}
        title="Sure you want to continue later?"
    >
        {isSalaryDate ?
            <React.Fragment>If you choose to do it later, we’ll save your progress until <span className="d-inline-block">{dateExpired}</span></React.Fragment> :
            <React.Fragment>We just need your physical address to present you with the contract. If you choose to do it later, we’ll save your progress until <span className="d-inline-block">{dateExpired}</span>.</React.Fragment>}
    </TruidWrapDialogContent>
);

export const TruidAffordabilityTimeout = () => (
    <TruidWrapDialogContent
        icon={<MailIcon />}
        title="We need more time to verify your statements"
        listContent={[
            'Looks like a technical issue. It may take a little longer for us to verify your bank statements.',
            'We’ll send you an email and SMS when we’ve resolved the issue. Then you can continue with the process for this application.',
        ]}
    />
);

export const TruidAffordabilityCancel = ({ date }) => (
    <TruidWrapDialogContent
        icon={<DocumentIcon />}
        title="We need access to your bank statements to give final approval"
    >
        We’ll need to confirm your affordability to disburse the loan funds. Your application progress will be saved until <span className="d-inline-block">{date}</span>.
    </TruidWrapDialogContent>
);

export const TruidAffordabilityBeforeLeave = ({ date }) => (
    <TruidWrapDialogContent
        icon={<MailIcon />}
        title="We sent you email!"
        listContent={[
            'You can come back via the email we sent you to continue.',
            <React.Fragment key="1">
                Your application progress will be saved until <span className="d-inline-block">{date}</span>.
                After that, you will need to reapply for the loan from the beginning.
            </React.Fragment>,
        ]}
    />
);

export const formatAddressDisplay = value => value ? `, ${value}` : '';

const TruidConfirmChangeAddress = ({
    loanApplicationNumber,
    stepUpToken,
    formData,
}) => {
    const canSaveProvince = canEditAddressProvince();

    // we save some info
    const { postChange, isFetch } = useSaveAddress({
        address: formData.address,
        city: formData.city,
        zipCode: formData.zipCode,
        suburb: formData.suburb,
        province: canSaveProvince ? formData.province : undefined,
        loanApplicationNumber: formData.loanApplicationNumber,
    }, loanApplicationNumber, stepUpToken);

    const parseProvince = formatAddressDisplay(formData.province);
    const parseSuburb = formatAddressDisplay(formData.suburb);

    return (
        <WrapperIconModal
            icon={<HomeAddressIcon />}
            title="Confirm your address"
        >
            <p className="label-guilde text-center text-wrap">
                {`${formData.address}${parseSuburb}, ${formData.city}${parseProvince}, ${formData.zipCode}, South Africa`.trim()}
            </p>

            <WrapAction className="form-group d-md-flex flex-md-row justify-content-center flex-column-reverse d-flex d-md-block">
                <PLButton
                    primary={false}
                    onClick={() => ScreenBusUtils.showTruIDFormAddress(formData)}
                >
                    Change address
                </PLButton>
                <PLButton
                    disabled={isFetch}
                    onClick={() => {
                        postChange();
                        SegmentBusTrackTruID.segment1500MissingAddressConfirmData();
                    }}
                >
                    Confirm
                </PLButton>
            </WrapAction>
        </WrapperIconModal>
    );
};

export default TruidConfirmChangeAddress;
