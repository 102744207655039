import React, { useState } from 'react';

import PLAutoComplete from '../Common/PLAutoComplete';
import { canSearchText, findCityBySuburb, isStartsWithSelection } from './TruidUtils';

const TruidSuburbComplete = ({
    listData = [],
    administrativeData = [],
    formData = {},
    handleSelection,
    isOpen,
}) => {
    const [show, setShow] = useState(isOpen);
    const listFilter = filterListData();

    function filterListData() {
        const text = `${formData.suburb}`.trim();
        if (!show || !canSearchText(text)) {
            return [];
        }

        return listData.filter(item => isStartsWithSelection(item.suburb, text));
    }

    return (
        <PLAutoComplete
            className="autocomplete-base-input"
            classNameInput="form-control-address"
            nameText="suburb"
            nameValue="suburb"
            noDataMsg=""
            maxLength={50}
            text={formData.suburb}
            showing={show}
            disabledSearchIcon
            listSuggest={listFilter}
            formatDropDownItem={item => (
                <span className="d-flex flex-column">
                    <span className="font-weight-400 font-size-18">{item.suburb}</span>
                    <span>{item.city}, {item.province}, {item.zipCode}</span>
                </span>
            )}

            setText={(newValue) => {
                if (newValue.trim() !== formData.suburb.trim()) {
                    handleSelection({ freeText: newValue.trim() });
                    setShow(true);
                }
            }}

            handleSelection={(item) => {
                handleSelection({
                    item: {
                        ...item,
                        cities: findCityBySuburb({
                            ...item,
                            suburbSugestions: listData,
                            administrativeData,
                        })
                    },
                });
                setShow(false);
            }}

            handleOutSideClick={() => setShow(false)}
        />
    );
};

export default TruidSuburbComplete;
