import { ErrorCode } from '../constants';
import { WrapSegmentBusUtils } from '../hooks/useSegmentEventBus';

import { SegmentBusTrackFacials } from './SegmentBusTrackFacials';

export const segmentTrackCode = {
    // offer decline by SAID vs PHONE: FACIAL
    [ErrorCode.FACIAL_OFFER_RETRACTED]:
        () => SegmentBusTrackFacials.segment2100FacialVerificationSaIDErrorNoHanisPhotoData(),
};

export class SegmentBusTrackCode {
    static tracking(code, params) {
        const t = segmentTrackCode[code];
        if (t) {
            t(params);
        }
    }

    static segment2200LoanPageErrorDisplayedData(params) {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2200LoanPageErrorDisplayedData', params);
    }
}
