import {
    API_HEADER_INPUTS,
    EMAIL_LOAN_DOC,
    EMAIL_LOAN_DOC_SIT,
    EMAIL_LOAN_DOC_UAT,
    apiPROD,
    apiSIT,
    apiUAT,
} from './constants';

import { getProvider } from './utils/commonUtils';

class Config {
    constructor() {
        this.currency = '';
        this.country = '';
        this.channel = API_HEADER_INPUTS.CHANNEL_WEB;
        this.i18n = {};

        this.provider = 'TFG';

        this.loanAmount = {
            min: 10000,
            max: 50000,
        };

        this.apiService = {
            contextPath: '/los/v1.0.0/lending-origination',
            ldsContextPath: '/lds/v1.0.0/lending-decisioning',
            extLcsContextPath: '/ext-lcs/v1.0.0/lending-completion',
            lcsContextPath: '/lcs/v1.0.0/lending-completion',
        };

        this.validation = {
            age: {
                minimum: 18,
            },
        };
        this.decisioning = {
            requestFrequentSpan: 10000,
            requestDecisionUpsellSpan: 7500,
            requestTimeTruid: 30 * 1000,
            retryCount: 5,
            requestTimeWhenUpdateMissing: 5 * 1000,
        };

        this.loanOfferTiming = {
            timeCounter: 2,
            timeDelay: 10 * 1000,
        };

        this.facialTiming = {
            timeCounter: 6,
            timeDelay: 15 * 1000,
        };

        const domain = window.location.href;

        this.productName = getProvider() === 'TFG' ? 'TymeBank TFG Money' : 'TymeBank';

        const regexDomainSit = /\b(\w*pl.testtymebank.co.za\w*)\b/g.test(domain);
        const regexDomainUat = /\b(\w*pl.stgtymebank.co.za\w*)\b/g.test(domain);
        const regexDomainProd = /\b(\w*loan.tymebank.co.za\w*)\b/g.test(domain);

        if (regexDomainProd) {
            this.host = apiPROD;
            this.GOOGLE_RECAPTCHA_SITEKEY = process.env.REACT_APP_PROD_GOOGLE_RECAPTCHA_SITEKEY;
            this.GaDefaultConfig = {
                trackingId: 'GTM-PCQMX58' || process.env.REACT_APP_PROD_GA_TRACKING_ID, // ID WEB-Site
                debug: false,
                gaOptions: {
                    cookieDomain: 'none',
                },
            };
            this.writeSegment = 'so8asL1UTUu6VmiFi1WRepIfC61team2'; // ID WEB-Site
            this.emailLoanDoc = EMAIL_LOAN_DOC;
            this.profileUrlActivation = 'https://activation.tymedigital.net/profile-activation/personal-loan';
        } else if (regexDomainSit) {
            this.host = apiSIT;
            this.GOOGLE_RECAPTCHA_SITEKEY = process.env.REACT_APP_SIT_GOOGLE_RECAPTCHA_SITEKEY;
            this.GaDefaultConfig = {
                trackingId: 'G-L5FQQN51Q1' || process.env.REACT_APP_SIT_GA_TRACKING_ID, // test
                debug: true,
                gaOptions: {
                    cookieDomain: 'none',
                },
            };
            this.writeSegment = 'P9sK29N3g4DB9gMWVK9rd3AXWpvITASn'; // ID WEB-Site
            this.emailLoanDoc = EMAIL_LOAN_DOC_SIT;
            this.profileUrlActivation = 'https://activation.tymedigital.net/profile-activation/personal-loan';
        } else if (regexDomainUat) {
            this.host = apiUAT;
            this.GOOGLE_RECAPTCHA_SITEKEY = process.env.REACT_APP_UAT_GOOGLE_RECAPTCHA_SITEKEY;
            this.GaDefaultConfig = {
                trackingId: 'GTM-PG28SQH' || process.env.REACT_APP_UAT_GA_TRACKING_ID, // ID WEB-Site
                debug: false,
                gaOptions: {
                    cookieDomain: 'none',
                },
            };
            this.writeSegment = 'PDDIQCXfiqXRTpGPqCEGKk01NeG2uSR6'; // ID WEB-Site
            this.emailLoanDoc = EMAIL_LOAN_DOC_UAT;
            this.profileUrlActivation = 'https://activation.tymedigital.net/profile-activation/personal-loan';
        } else {
            this.host = apiUAT;
            this.GOOGLE_RECAPTCHA_SITEKEY = process.env.REACT_APP_SIT_GOOGLE_RECAPTCHA_SITEKEY;
            this.GaDefaultConfig = {
                trackingId: 'G-L5FQQN51Q1', // TEST
                debug: true,
                gaOptions: {
                    cookieDomain: 'none',
                },
            };
            this.emailLoanDoc = EMAIL_LOAN_DOC_SIT;
            this.writeSegment = 'P9sK29N3g4DB9gMWVK9rd3AXWpvITASn';
            this.profileUrlActivation = 'https://activation.tymedigital.net/profile-activation/personal-loan';
        }

        if (!regexDomainProd) {
            this.nonProd = true;
            this.isLaunchingAutomation = /\b(\w*?isAutomationLaunching=true\w*)\b/g.test(domain);
            if (this.isLaunchingAutomation) {
                // public test key from google. Thus, there's no security issue.
                this.GOOGLE_RECAPTCHA_SITEKEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
            }
        }
    }
}

export const AppConfig = new Config();
export default AppConfig;
