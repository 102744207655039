/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { ScreenBusUtils } from '../hooks/useEventBus';
import { DecisioningResult, UpsellingResultStatus } from '../constants';
import { useCommonContext } from '../context/CommonContext';
import { fetchDecisionResults } from '../actions';
import { execShowMissingFormWhenDeclineCode } from '../utils/commonUtils';
import { SegmentBusTrackDecisionEngine } from '../segment-bus/SegmentBusTrackDecisionEngine';

// components
import ErrorBoundary from '../components/Common/ErrorBoundary';
import PLButton from '../components/Common/PLButton';
import OfferViewChoices from '../components/Package/OfferViewChoices';

function Offers({
    data,
    loanSubmission,
    verifyLoanApplicationData,
    ...props
}) {
    const { declineSpecialCode } = useCommonContext();

    const resumeNumber = _.get(verifyLoanApplicationData, 'loanApplicationNumber');
    const loanApplicationNumber = _.get(loanSubmission, 'loanApplicationNumber') || resumeNumber;
    const loanStatus = _.get(verifyLoanApplicationData, 'loanApplicationStatus');

    const [offers, setOffers] = useState(() => _.get(data, 'offerList', _.get(data, 'state.offerList') || []));

    useEffect(() => {
        if (loanApplicationNumber && !UpsellingResultStatus.YES[data.upsellStatus]) {
            setTimeout(() => props.fetchDecisionResults({ loanApplicationNumber }));
        }
        SegmentBusTrackDecisionEngine.segment1300DecisionConditionallyApprovedData();
    }, []);

    useEffect(() => {
        if (resumeNumber && loanStatus === DecisioningResult.loanApplicationStatus.CONDITIONALLY_APPROVED_COLLECTION_RECEIVED) {
            ScreenBusUtils.setMessageLoading('Please wait while we resume your application. This might take a couple of minutes.');
        }
    }, [loanStatus]);

    useEffect(() => {
        if (UpsellingResultStatus.YES[data.upsellStatus]) {
            ScreenBusUtils.gotoScreenUpsellOffers();
        }
    }, [data]);

    useEffect(() => {
        setOffers(_.get(data, 'offerList', _.get(data, 'state.offerList') || []));
    }, [_.get(data, 'offerList'), _.get(data, 'state.offerList')]);

    const onClickNext = () => {
        execShowMissingFormWhenDeclineCode(declineSpecialCode)
            .then(result => {
                if (result) {
                    ScreenBusUtils.clearDeclineStatus();
                    return;
                }
                ScreenBusUtils.gotoScreenIntroTruID();
            });
    };

    const amount = _.get(offers[0], 'finalApprovedAmount');
    const realOfferData = data || _.get(data, 'state');
    const incomeVerificationExpired = _.get(realOfferData, 'incomeVerificationExpired');
    const dateExpired = moment(new Date(incomeVerificationExpired)).format('DD/MM/YYYY');

    return (
        <div id="offers">
            <ErrorBoundary componentName="offer-screen">
                <OfferViewChoices
                    mainFlow
                    amount={amount}
                    loanApplicationNumber={loanApplicationNumber}
                    offers={offers}
                    dateExpired={dateExpired}
                />

                <div className="buttons my-4">
                    <PLButton onClick={() => onClickNext()}>
                        Next
                    </PLButton>
                </div>
            </ErrorBoundary>
        </div>
    );
}

const mapStateToProps = state => ({
    data: state.offers,
    loanSubmission: state.loanSubmission,
    verifyLoanApplicationData: state.verifyLoanApplicationData,
});

export default connect(
    mapStateToProps,
    {
        fetchDecisionResults,
    },
)(Offers);
