import useEventListener from './useEventListener';
import { isHomePage } from '../utils/pageUtils';

export const handleBeforePageLeave = (e, pathname) => {
    // ignore home page
    if (isHomePage(pathname)) {
        return true;
    }

    e.preventDefault();
    e.returnValue = '';
    return undefined;
};

export const useBeforePageUnload = (pathname) => {
    // eslint-disable-next-line consistent-return
    useEventListener('beforeunload', (e) => handleBeforePageLeave(e, pathname));
};
