import { WrapSegmentBusUtils } from '../hooks/useSegmentEventBus';

// Updating
export class SegmentBusTrackOtp {
    static segment500OtpResumeCheckScreenDisplayedData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment500OtpResumeCheckScreenDisplayedData');
    }

    static segment500OtpCheckScreenDisplayedData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment500OtpCheckScreenDisplayedData');
    }

    static segment500LoanPuplateDataDisplayedData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment500LoanPuplateDataDisplayedData');
    }
}
