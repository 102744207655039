import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import joinclass from 'joinclass';

import { formatMoney } from '../../utils/format';
import { useModalPopupContext } from '../../context/ModalPopupContext';
import { useCommonContext } from '../../context/CommonContext';

import useEventBus, { EventBusName, ScreenBusUtils } from '../../hooks/useEventBus';
import WrapperIconModal from '../Common/WrapperIconModal';
import ErrorBoundary from '../Common/ErrorBoundary';

import TruidAutoCompleteSearchText from './TruidAutoCompleteSearchText';
import TruidFormEditAddress from './TruidFormEditAddress';
import TruidConfirmChangeAddress from './TruidConfirmChangeAddress';

import { useParseAddressDetails } from './useHandleTruidAddress';

// resource
import LocationIcon from '../../assets/images/elements/LocationIcon';

const TruidAddressUpdate = ({
    loanApplicationNumber,
    stepUpToken,
    addressData = {},
    loanInfo,
    restrictAccount,
}) => {
    const { showModal, hideModal } = useModalPopupContext();
    const { saAdministrative } = useCommonContext();

    const {
        administrativeData = [],
        citySugestions = [],
        suburbSugestions = [],
    } = saAdministrative;

    const [lockExpiredStatus, clearLockExpiredStatus] = useState(false);

    const [formData, setFormData] = useState({
        address: '',
        city: '',
        zipCode: '',
        country: '',
        placeId: '',
        suburb: '',
        province: '',
        ...addressData,
    });

    useEventBus(EventBusName.REFRESH_API_TOKEN, (isExpired) => {
        clearLockExpiredStatus(isExpired);
        setFormData({
            ...formData,
            active: true,
        });
    });

    const { infoForm } = useParseAddressDetails({
        placeId: formData.placeId, description: formData.description,
        saAdministrative,
    });

    const amount = _.get(loanInfo, 'offerList[0].finalApprovedAmount');
    const dateExpired = moment(new Date(loanInfo.incomeVerificationExpired)).format('DD/MM/YYYY');

    useEffect(() => {
        if (infoForm) {
            setFormData(infoForm);
        }
    }, [infoForm]);

    return (
        <WrapperIconModal
            icon={<LocationIcon />}
            title={
                <React.Fragment>You’re fully approved for <span className="d-inline-block">{formatMoney(amount)}</span></React.Fragment>
            }
        >
            <p className="text-center">
                {
                    restrictAccount ?
                        'TESTING ONLY - FOR SOME ACCOUNTS' :
                        `Unfortunately we couldn't get your address from your primary bank or we received it in an incorrect format. We’ll need you to provide us with your address so that you can sign the contract.`
                }
            </p>

            <div className="w-100">
                <div className={joinclass('form-group', lockExpiredStatus && 'has-error')}>
                    <ErrorBoundary componentName="truid-auto-complete-search-text">
                        <TruidAutoCompleteSearchText
                            lockExpiredStatus={lockExpiredStatus}
                            clearLockExpiredStatus={clearLockExpiredStatus}
                            handleChange={() => {
                                setFormData({ ...formData, active: false });
                            }}
                            handleSelectePlaceId={(placeId, description) => {
                                setFormData({ ...formData, placeId, description });
                            }}
                        />
                    </ErrorBoundary>
                </div>

                <ErrorBoundary componentName="truid-form-edit-address">
                    <TruidFormEditAddress
                        citySugestions={citySugestions}
                        suburbSugestions={suburbSugestions}
                        administrativeData={administrativeData}
                        dateExpired={dateExpired}
                        formData={formData}
                        setFormData={newVal => setFormData({ ...newVal })}
                        onNext={() => {
                            if (restrictAccount) {
                                hideModal();
                                return;
                            }
                            showModal({
                                modalContent: (
                                    <ErrorBoundary componentName="truid-form-confirm-address">
                                        <TruidConfirmChangeAddress
                                            loanApplicationNumber={loanApplicationNumber}
                                            stepUpToken={stepUpToken}
                                            formData={formData}
                                        />
                                    </ErrorBoundary>
                                ),
                                showOK: false,
                                disabledClose: true,
                            });
                        }}
                    />
                </ErrorBoundary>
            </div>
        </WrapperIconModal >
    );
};

const mapStateToProps = state => ({
    loanApplicationNumber: _.get(state.loanSubmission, 'loanApplicationNumber') || _.get(state.verifyLoanApplicationData, 'loanApplicationNumber'),
    stepUpToken: state.otp.stepUpToken,
    loanInfo: state.offers,
});

export default connect(
    mapStateToProps,
    null,
)(TruidAddressUpdate);
