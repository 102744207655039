/**
 * Don't use reducer and Epic, because it don't share data
 */
import React, { useRef, useState } from 'react';
import joinclass from 'joinclass';
import NumberFormat from 'react-number-format';

import { canEditAddressProvince } from '../../toogle';

import { ScreenBusUtils } from '../../hooks/useEventBus';
import useTimeout from '../../hooks/useTimeout';
import { scrollElementIfNeeded } from '../../utils/commonUtils';

import PLButton from '../Common/PLButton';
import { WrapAction } from '../Common/ActionButtons';
import WrapSelect from '../Common/WrapSelect';
import ErrorBoundary from '../Common/ErrorBoundary';

import TruidSuburbComplete from './TruidSuburbComplete';
import TruidCityComplete from './TruidCityComplete';
import { findProvinceItem, validateAddress } from './TruidUtils';
import useTruIDConfirmChange from './useTruIDConfirmChange';

import provinceData from './assets/truid-province-data.json';

const TruidFormEditAddress = ({
    dateExpired,
    formData,
    setFormData,
    isFetch,
    suburbSugestions = [],
    citySugestions = [],
    administrativeData = [],
    onNext = () => { },
}) => {
    const refAddress = useRef();
    const refSubmit = useRef();
    const refForm = useRef();
    const showConfirmLeaveChange = useTruIDConfirmChange();

    const [active, setActive] = useState(false);
    const readyActive = active || Boolean(formData.active);

    // Dont install react-hook-form and validate library.
    // The basic form don't need a complex validation. (large size -> when a library is installed)
    const resultValidate = validateAddress(formData);

    useTimeout(() => refAddress.current.focus(), 250);

    function handleChange(evt) {
        setFormData({
            ...formData,
            [evt.target.name]: evt.target.value,
        });
    }

    function focusInputError() {
        setTimeout(() => {
            const element = refForm.current.querySelector('.has-error .form-control-address');
            if (element) {
                scrollElementIfNeeded(element);
                element.focus();
            }
        });
    }

    return (
        <section ref={refForm} className="truid-form-address">
            <div className={joinclass('form-group', readyActive && resultValidate.isErrorAddress && 'has-error')}>
                <div>
                    <label>Street address</label>
                    <input
                        ref={refAddress}
                        className="form-control form-control-address"
                        value={formData.address}
                        autoComplete="off"
                        name="address"
                        maxLength={255}
                        onChange={handleChange}
                    />
                </div>
                <div className="help-block with-errors">Enter a valid street address</div>
            </div>

            <div className={joinclass('form-group', readyActive && resultValidate.isErrorSuburb && 'has-error')}>
                <ErrorBoundary componentName="truid-suburb">
                    <div>
                        <label>Suburb</label>
                        <TruidSuburbComplete
                            listData={suburbSugestions}
                            administrativeData={administrativeData}
                            formData={formData}
                            handleSelection={({ item, freeText }) => {
                                if (!item) {
                                    setFormData({
                                        ...formData,
                                        suburb: freeText,
                                        cities: [],
                                    });
                                    return;
                                }
                                setFormData({
                                    ...formData,
                                    ...item,
                                });
                            }}
                        />
                    </div>
                    <div className="help-block with-errors">Enter a valid suburb</div>
                </ErrorBoundary>
            </div>

            <div className={joinclass('form-group', readyActive && resultValidate.isErrorCity && 'has-error')}>
                <ErrorBoundary componentName="truid-city">
                    <div>
                        <label>City</label>
                        <TruidCityComplete
                            listData={citySugestions}
                            formData={formData}
                            handleSelection={({ item, freeText }) => {
                                if (!item) {
                                    setFormData({
                                        ...formData,
                                        city: freeText,
                                    });
                                    return;
                                }

                                setFormData({
                                    ...formData,
                                    city: item.city,
                                    province: findProvinceItem(item.province),
                                });
                            }}
                        />
                    </div>
                    <div className="help-block with-errors">Enter a valid city</div>
                </ErrorBoundary>
            </div>

            {canEditAddressProvince() &&
                <div className={joinclass('form-group', readyActive && resultValidate.isErrorProvince && 'has-error')}>
                    <ErrorBoundary componentName="truid-province">
                        <div>
                            <label>Province</label>
                            <WrapSelect
                                className="form-control-address"
                                fullHeight
                                listData={provinceData}
                                value={formData.province}
                                handleSelection={province => {
                                    setFormData({ ...formData, province });
                                }}
                            />
                        </div>
                        <div className="help-block with-errors">Select a valid province</div>
                    </ErrorBoundary>
                </div>
            }
            <div className={joinclass('form-group', readyActive && resultValidate.isErrorZipCode && 'has-error')}>
                <div>
                    <label>Postal code</label>
                    <NumberFormat
                        name="zipCode"
                        className="form-control form-control-address"
                        value={formData.zipCode}
                        format="#####"
                        type="tel"
                        onChange={handleChange}
                    />
                </div>
                <div className="help-block with-errors">{resultValidate.msgZipCode}</div>
            </div>

            <WrapAction className="form-group d-md-flex flex-md-row justify-content-center flex-column-reverse d-flex d-md-block">
                <PLButton
                    primary={false}
                    onClick={() => {
                        showConfirmLeaveChange({
                            dateExpired,
                            handleOK: () => ScreenBusUtils.showTruIDFormAddress(formData),
                        });
                    }}
                >
                    I'll do it later
                </PLButton>

                <PLButton
                    refElement={refSubmit}
                    disabled={isFetch}
                    onClick={() => {
                        setActive(true);
                        resultValidate.isErrorAny ? focusInputError() : onNext(formData);
                    }}
                >
                    Next
                </PLButton>
            </WrapAction>
        </section>
    );
};

export default TruidFormEditAddress;
