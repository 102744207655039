/*
+ Should migrate framework:
+  - Using hook for clean code.
+  - Easy apply.
+  - Limited redux, save memory.
+  - Single request
*/
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { $emit } from 'event-bus-e2z';

import config from '../config';
import { API_HOST_AND_LDS_CONTEXT, useGet, usePost } from './useService';
import useEventBus, { EventBusName } from '../hooks/useEventBus';
import { parseCitySugestions, parseSuburbSuggestions } from '../components/TruId/TruidUtils';

const sessiontoken = '1234567890';
const API_HOST = config.host;

const saAdministrativeURL = "./assets/sa-administrative-data.json";

/** Single update truid address */
export const useUpdateAddressInformation = ({ data, stepUpToken }) => {
  const url = `${API_HOST_AND_LDS_CONTEXT}/loan-applications/update-address`;
  return usePost({
    url,
    bodyData: data,
    headers: { 'Step-Up-Token': stepUpToken },
  });
};

export const useUpdateSalaryDate = ({ data, stepUpToken } = {}) => {
  const url = `${API_HOST_AND_LDS_CONTEXT}/loan-applications/update-salary-date`;
  return usePost({
    url,
    bodyData: data,
    headers: { 'Step-Up-Token': stepUpToken },
  });
};

export const useSearchFreeAddr = ({
  // key,
  text = '',
  noLockScreen = false,
}) => {
  const result = useGet({
    url: `${API_HOST}/los/v1.0.0/maps/place/v1.0.0/autocomplete/json`,
    paramData: {
      input: text,
      components: 'country:za',
      types: 'establishment',
      fields: 'address_components,geometry',
      sessiontoken,
    },
    noLockScreen,
  });

  useEffect(() => {
    const code = _.get(result, 'errors.status');
    if (code === 401) {
      $emit(EventBusName.REFRESH_API_TOKEN, true);
    }
  }, [result.errors]);

  return result;
};

export const useSearchAddressByPlaceId = ({
  // key,
  placeId = '',
  noLockScreen,
}) => {
  const result = useGet({
    url: `${API_HOST}/los/v1.0.0/maps/place/v1.0.0/details/json`,
    paramData: {
      place_id: placeId,
      fields: 'place_id,address_component,adr_address,geometry',
      sessiontoken,
    },
    noLockScreen,
  });

  useEffect(() => {
    const code = _.get(result, 'errors.status');
    if (code === 401) {
      $emit(EventBusName.REFRESH_API_TOKEN, true);
    }
  }, [result.errors]);
  return result;
};

export const useGetSaAdministrative = () => {
  const [saAdministrative, setSaAdministrative] = useState({
    administrativeData: [],
    suburbSugestions: [],
    citySugestions: [],
  });

  const { data, refetch, errors, loading } = useGet({
    url: saAdministrativeURL,
    noLockScreen: true,
  });

  useEventBus(EventBusName.FECTCHING_SA_ADMINITRATIVE_DATA, () => {
    if (!loading) {
      refetch();
    }
  })

  useEffect(() => {
    if (data) {
      const suburbSugestions = parseSuburbSuggestions(data);
      const citySugestions = parseCitySugestions(data);

      setSaAdministrative({
        suburbSugestions,
        citySugestions,
        administrativeData: data,
      });
    }
  }, [data, loading, errors])

  return saAdministrative;
};
