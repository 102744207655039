import React, { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import joinclass from 'joinclass';

import useOnClickOutside from '../../hooks/useOnClickOutside';
import useEventListener from '../../hooks/useEventListener';
import { FlexRowCenter } from '../Common/FlexCenter';
import { scrollElementIfNeeded } from '../../utils/commonUtils';

import SearchIcon from '../../assets/images/elements/SearchIcon';
import LoadingIcon from '../../assets/images/elements/LoadingIcon';
import CloseIcon from '../../assets/images/elements/CloseIcon';

const PLAutoComplete = ({
    nameText,
    nameValue,
    className,
    classNameInput,
    listSuggest = [],
    text = '',
    isLoading,
    maxLength,
    showing,
    hasAsync,
    placeholder = '',
    noDataMsg = 'There is no data',
    activeIconSearch,
    disabledSearchIcon,
    formatDropDownItem,
    setText = () => { },
    handleSelection = () => { },
    handleOutSideClick = () => { },
    handleIconSearch = () => { },
}) => {
    const [id] = useState(`pl-sugggest-${v4()}`);
    const [completeValue, setCompleteValue] = useState({
        localText: text,
        localValue: '',
    });

    const refsIndexFocus = useRef();
    const refsSearch = useRef();
    const refsContainer = useRef();

    const hasSearch = `${completeValue.localText}`.trim().length > 0;
    const showList = showing && !isLoading && hasSearch;
    const hasOptions = listSuggest.length > 0;

    useEventListener('keydown', (event) => {
        if (event.keyCode === 9) {
            handleOutSideClick();
            refsIndexFocus.current = -1;
        }
    });

    useOnClickOutside(refsContainer, () => {
        if (showList) {
            handleOutSideClick();
            refsIndexFocus.current = -1;
        }
    });

    // optimize later
    useEffect(() => {
        setCompleteValue({
            ...completeValue,
            localText: text,
        });
    }, [text]);

    const bindAutoComplete = () => {
        refsIndexFocus.current = -1;
        refsSearch.current.addEventListener('keydown', (e) => {
            let x = document.getElementById(`${id}-autocomplete-list`);
            if (x) {
                x = x.getElementsByTagName('div');
            }

            if (e.keyCode === 40) { // DOWN
                // eslint-disable-next-line no-plusplus
                refsIndexFocus.current++;
                addActive(x);
                return;
            }

            if (e.keyCode === 38) { // UP
                // eslint-disable-next-line no-plusplus
                refsIndexFocus.current--;
                addActive(x);
                return;
            }

            if (e.keyCode === 13) { // ENTER
                e.preventDefault();
                if (refsIndexFocus.current > -1) {
                    if (x && x[refsIndexFocus.current]) {
                        x[refsIndexFocus.current].click();
                    }
                }
            }
        });

        function addActive(x) {
            if (!x) {
                return false;
            }
            removeActive(x);

            if (refsIndexFocus.current >= x.length) {
                refsIndexFocus.current = 0;
            }

            if (refsIndexFocus.current < 0) {
                refsIndexFocus.current = x.length - 1;
            }

            x[refsIndexFocus.current].classList.add('autocomplete-active');
            scrollElementIfNeeded(x[refsIndexFocus.current]);
            return true;
        }

        function removeActive(x) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < x.length; i++) {
                x[i].classList.remove('autocomplete-active');
            }
        }
    };

    useEffect(() => {
        bindAutoComplete();
    }, []);

    return (
        <div
            ref={refsContainer}
            className={joinclass('autocomplete', disabledSearchIcon && 'autocomplete-hide-search', className)}
        >
            {!disabledSearchIcon &&
                <FlexRowCenter
                    className={
                        joinclass(
                            'autocomplete_loading',
                            activeIconSearch && 'autocomplete-search-icon__active',
                            isLoading && 'search-icon__busy',
                        )
                    }
                    onClick={() => !isLoading && handleIconSearch()}
                >
                    {(hasAsync && isLoading && hasSearch) ? <LoadingIcon /> : <SearchIcon />}
                </FlexRowCenter>
            }

            <input
                id={id}
                ref={refsSearch}
                value={completeValue.localText}
                autoComplete="off"
                maxLength={maxLength}
                className={classNameInput}
                type="text"
                placeholder={placeholder}
                onChange={evt => {
                    setCompleteValue({
                        ...completeValue,
                        localText: evt.target.value,
                    });
                    setText(evt.target.value);
                }}
            />

            {hasSearch &&
                <FlexRowCenter
                    className="autocomplete_clear"
                    onClick={() => {
                        refsSearch.current.focus();
                        setCompleteValue({
                            ...completeValue,
                            localText: '',
                        });
                        setText('');
                    }}
                >
                    <CloseIcon />
                </FlexRowCenter>
            }

            {showList &&
                <div id={`${id}-autocomplete-list`} className="autocomplete-items">
                    {hasOptions ?
                        listSuggest.map((item, index) => (
                            <div
                                key={`${item[nameValue]}-${index}`}
                                onClick={() => {
                                    setCompleteValue({
                                        localText: item[nameText],
                                        localValue: item[nameValue],
                                    });
                                    handleSelection(item);
                                    refsIndexFocus.current = -1;
                                }}
                                aria-hidden="true"
                            >
                                {formatDropDownItem ? formatDropDownItem(item) : item[nameText]}
                            </div>
                        ))
                        : (
                            noDataMsg && <div className="autocomplete-items-empty">{noDataMsg}</div>
                        )
                    }
                </div>
            }
        </div >
    );
};

export default PLAutoComplete;
