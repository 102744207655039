import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import PLAutoComplete from '../Common/PLAutoComplete';
import { useSearchFreeAddr } from '../../services/useTruidService';
import { canSearchText } from './TruidUtils';

const TruidAutoCompleteSearchText = ({
    lockExpiredStatus,
    clearLockExpiredStatus,
    handleChange,
    handleSelectePlaceId,
}) => {
    const [text, setText] = useState('');

    const [loadingStatus, setLoadingStatus] = useState({
        fakeLoading: false,
        showing: false,
    });

    // fetch suggestion address from google api
    const {
        data, loading, refetchParam, cancelRequest,
    } = useSearchFreeAddr({
        text,
        noLockScreen: true,
    });

    const getAddressSuggest = useCallback(_.debounce((nextValue) => {
        cancelRequest();
        refetchParam({ newParams: { input: nextValue.trim() }, mergeParams: true });
    }, 1000), []);

    const listSuggest = _.get(data, 'predictions', []);
    const isFetching = loadingStatus.fakeLoading || loading;
    const activeIconSearch = canSearchText(text, 2);

    const hasSearchNoData = !isFetching && activeIconSearch && listSuggest.length === 0;

    useEffect(() => {
        if (!loading) {
            setLoadingStatus({
                ...loadingStatus,
                fakeLoading: false,
            });
        }
    }, [loading]);

    const clearAutoComplete = () => {
        cancelRequest();
        setLoadingStatus({
            fakeLoading: false,
            showing: false,
        });
        clearLockExpiredStatus(false);
    };

    const message = () => {
        if (lockExpiredStatus) {
            return (
                <React.Fragment>
                    Your address could not be processed. Please try again or manually input the address in the form below.
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                Your address could not be found. Please try again with another keywords or manually input the address in the form below.
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <PLAutoComplete
                nameText="description"
                nameValue="place_id"
                placeholder="Search your address"
                noDataMsg=""
                isLoading={isFetching}
                showing={loadingStatus.showing}
                listSuggest={listSuggest}
                hasAsync
                text={text}
                setText={(newValue) => {
                    setText(newValue);
                    // clear searching
                    if (!canSearchText(newValue, 2)) {
                        clearAutoComplete();
                        return;
                    }

                    if (newValue.trim() !== text.trim()) {
                        getAddressSuggest(newValue);
                        handleChange();
                        setLoadingStatus({
                            fakeLoading: true,
                            showing: true,
                        });
                    }
                }}

                handleSelection={(item) => {
                    clearAutoComplete();
                    handleSelectePlaceId(item.place_id, item.description);
                }}
                handleOutSideClick={clearAutoComplete}
                activeIconSearch={activeIconSearch}
                handleIconSearch={() => {
                    if (activeIconSearch) {
                        getAddressSuggest(text);
                        setLoadingStatus({
                            fakeLoading: true,
                            showing: true,
                        });
                    }
                }}
            />
            {hasSearchNoData && <div className="with-errors d-block">{message()}</div>}
        </React.Fragment>
    );
};

export default TruidAutoCompleteSearchText;
