/* eslint-disable no-restricted-globals,no-unused-vars */
import { PHONE_LENGTH, VALIDATE_PHONE_PREFIX, Said } from '../constants';

export function formatZero(n) {
    return `00${n}`.slice(-2);
}

export const formatPercent = (percent = 0) => `${(percent * 100).toFixed(2)}%`;

export const formatMoney = (x, defaultValue = 0, fixed = 2) => {
    const xVal = removeComma(`${x}`);
    if (isNaN(Number(xVal))) {
        return `R${defaultValue || x || 0}`;
    }

    const nVal = fixed > 0 ? Number(xVal).toFixed(fixed) : xVal;
    const splitNumber = nVal.split('.'); // split xxxx.xx

    // format to comma
    const text = Number(splitNumber[0]).toLocaleString('en-US');

    const fmtSoNguyen = text.replace(/,/g, ' ');
    const fmtDecimal = splitNumber[1];
    if (fmtSoNguyen.startsWith('-')) {
        return `-R${fmtSoNguyen.slice(1)}.${fmtDecimal}`;
    }
    return `R${fmtSoNguyen}.${fmtDecimal}`;
};

export function removeComma(str) {
    if (str === undefined || str === null || str === '') {
        return '';
    }

    return str.replace(',', '');
}

export function removeMask(str) {
    if (str === undefined || str === null || str === '') {
        return '';
    }
    return `${str}`
        .toString()
        .replace(/\s/g, '')
        .replace(/_/g, '');
}

/**
 * Hide phone number
 * @param {String} phone phone number as string
 */
export function hidePhoneNumber(phone) {
    if (!phone) {
        return '';
    }

    const lastFour = phone.slice(phone.length - 4);
    return `*** *** ${lastFour}`;
}

export function formatPhoneNumber(phone) {
    if (phone === undefined || phone === null) {
        return '';
    }
    const re = /\(?(.{4})\)?[- ]?(.{3})[- ]?(.{3})/g;
    const subst = '$1 $2 $3';
    const result = phone.replace(re, subst);
    return result;
}

export function getNumberFromString(value) {
    if (value === undefined || value === null) return null;
    return Number(value.replace(/\s/g, ''));
}

export function validatePhoneNum(phone) {
    return VALIDATE_PHONE_PREFIX.includes(phone.slice(0, 2));
}

export function formatSAID(value = '') {
    // ###### #### ## #
    const splitString = `${value}`.split('');
    if (splitString.length >= 6) {
        splitString[5] = `${splitString[5]} `;
    }
    if (splitString.length >= 10) {
        splitString[9] = `${splitString[9]} `;
    }

    if (splitString.length >= 12) {
        splitString[11] = `${splitString[11]} `;
    }

    return splitString.join('').trim();
}

export function removeSpace(value = '') {
    return `${value}`.replace(/\s/g, '');
}

export function parseSAID(value = '') {
    return value.replace(/\D/g, '').slice(0, Said.LENGTH);
}

export function parsePhoneNumber(value = '') {
    return value.replace(/\D/g, '').slice(0, PHONE_LENGTH.LENGTH);
}
