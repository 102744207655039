/* eslint-disable no-shadow */
/* eslint-disable indent */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import _ from 'lodash';
import joinclass from 'joinclass';

import { hideModalHandleErrorApi } from '../actions/index';
import Config from '../config';
import keys from '../i18n/translationKeys';
import PLButton from './Common/PLButton';
import { WrapAction } from './Common/ActionButtons';
import { ScreenBusUtils } from '../hooks/useEventBus';

import { BUTTON, CALL_CENTER_NUMBER, ErrorCode } from '../constants';
import { SegmentBusTrackFacials } from '../segment-bus/SegmentBusTrackFacials';

import errorIcon from '../assets/images/error.svg';
import inforIcon from '../assets/images/infor.svg';
import cellFhoneErrorIcon from '../assets/images/cellphone-error.svg';
import optErrorIcon from '../assets/images/otp-error.svg';
import closeX from '../assets/images/close-gray.svg';

export class HandleErrorModal extends Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
    }

    /** Maybe: We need optimize source */
    componentWillReceiveProps(nextProps) {
        const { t } = this.props;
        if (!nextProps.errors) {
            return;
        }

        const onlyCloseModal = _.get(nextProps.errors, 'onlyCloseModal', false);
        if (onlyCloseModal) {
            const errors = _.get(nextProps.errors, 'errors', []);
            const { errorCode } = errors[0] || {};
            if (errorCode === ErrorCode.ADDRESS_ERROR_CODE) {
                this.setState({
                    icon: errorIcon,
                    button: 'Try again',
                    title: t(keys.handleErrors.HEADER_HAS_PROBLEM),
                    topContent: t(keys.handleErrors.TRUID_ADDRESS_CONTENT),
                    btmContent: ' ',
                    errorCode,
                    onlyCloseModal: true,
                });
                return;
            }
        }

        // we need to migrate framework
        const name = _.get(nextProps, 'errors.name');
        if (name === 'AjaxTimeoutError' || name === 'AjaxError') {
            this.handleGenericError(t, null);
        } else if (nextProps.errors && nextProps.errors.length > 0) {
            const { errorCode } = nextProps.errors[0];
            switch (errorCode) {
                case ErrorCode.INVALID_INVITATION:
                    this.setState({
                        icon: inforIcon,
                        button: BUTTON.GOT_IT,
                        title: t(keys.handleErrors.HEADER_MESSAGE_CANNOT_PROCEED),
                        topContent: t(keys.handleErrors.CONTENT_MESSAGE_NO_INVITATION),
                        btmContent: ' ',
                        errorCode: ErrorCode.INVALID_INVITATION,
                    });
                    break;
                case ErrorCode.UNDER18:
                    this.setState({
                        title: t(
                            keys.handleErrors.HEADER_MESSAGE_CANNOT_PROCEED,
                        ),
                        topContent: t(
                            keys.handleErrors.CONTENT_MESSAGE_UNDER18,
                        ),
                        btmContent: t(
                            keys.handleErrors.CONTENT_MESSAGE_CONTACT_CENTRE,
                        ),
                        errorCode: ErrorCode.UNDER18,
                    });
                    break;
                case ErrorCode.OTP_LIMIT_REACHED:
                    this.setState({
                        icon: optErrorIcon,
                        title: t(keys.handleErrors.OTP_LIMIT_HEADER),
                        topContent: t(keys.handleErrors.OTP_LIMIT_TOP),
                        btmContent: t(keys.handleErrors.OTP_LIMIT_BOTTOM),
                        errorCode: null,
                    });
                    break;
                case ErrorCode.OTP_CANNOT_VERIFY:
                    this.setState({
                        icon: cellFhoneErrorIcon,
                        title: t(keys.handleErrors.OTP_CANNOT_VERIFY_HEADER),
                        topContent: t(keys.handleErrors.OTP_CANNOT_VERIFY_TOP),
                        errorCode: null,
                    });
                    break;
                case ErrorCode.OTP_EXPIRED:
                    this.setState({
                        title: t(keys.handleErrors.OTP_EXPIRED_HEADER),
                        topContent: t(keys.handleErrors.OTP_EXPIRED_TOP),
                        btmContent: t(keys.handleErrors.OTP_EXPIRED_BOTTOM),
                        errorCode: ErrorCode.OTP_EXPIRED,
                    });
                    break;
                case ErrorCode.DE_TIMEDOUT:
                    this.setState({
                        title: 'We are sorry',
                        topContent: 'We\'ve encountered an issue and cannot display the result for your loan application. We are very sorry for this inconvenience.',
                        btmContent: 'If you need assistance, call us on 0860 999 119.',
                        errorCode: null,
                    });
                    break;
                case ErrorCode.EXISTING_INVITATION:
                    this.setState({
                        icon: inforIcon,
                        title: 'You already have an ongoing application',
                        topContent: 'Please click the button below to continue your existing Personal Loan application from where you left off',
                        btmContent: 'If you need assistance, call us on 0860 999 119.',
                        // errorCode: ErrorCode.EXISTING_INVITATION,
                        button: 'Continue application',
                        loanApplicationNumber: nextProps.errors[1],
                    });
                    break;
                case ErrorCode.EXISTING_TYMEBANK_PROFILE:
                case ErrorCode.EXISTING_TYMEBANK_PROFILE_BY_PASS_SALARY:
                    this.setState({
                        icon: inforIcon,
                        title: t(keys.handleErrors.HEADER_EXISTING_TYMEBANK_PROFILE),
                        topContent: t(keys.handleErrors.EXISTING_TYMEBANK_PROFILE),
                        btmContent: t(keys.handleErrors.EXISTING_TYMEBANK_PROFILE_BOTTOM),
                        button: BUTTON.GOT_IT,
                        errorCode: null,
                    });
                    break;
                case ErrorCode.AGE_MUST_BE_18:
                    this.setState({
                        title: t(keys.handleErrors.HEADER_AGE_MUST_BE_18),
                        topContent: t(keys.handleErrors.AGE_MUST_BE_18, {
                            productName: Config.productName,
                            contactCenter: CALL_CENTER_NUMBER,
                        }),
                        btmContent: ' ',
                        button: BUTTON.GOT_IT,
                        errorCode: null,
                    });
                    break;
                case ErrorCode.SAFPS_FAILED:
                    this.setState({
                        title: t(keys.handleErrors.HEADER_MESSAGE_CANNOT_PROCEED),
                        topContent: t(keys.handleErrors.SAFPS_FAILED),
                        btmContent: ' ',
                        errorCode: null,
                        button: BUTTON.EXIT,
                    });
                    break;
                case ErrorCode.IDV_FAILED:
                    this.setState({
                        title: t(keys.handleErrors.HEADER_MESSAGE_CANNOT_PROCEED),
                        topContent: t(keys.handleErrors.ASSISTANCE_MESSAGE, {
                            contactCenter: CALL_CENTER_NUMBER,
                        }),
                        btmContent: ' ',
                        button: BUTTON.EXIT,
                        errorCode: ErrorCode.IDV_FAILED,
                    });
                    break;
                case ErrorCode.IDN_BLOCK:
                    this.setState({
                        title: t(keys.handleErrors.HEADER_MESSAGE_CANNOT_PROCEED),
                        topContent: t(keys.handleErrors.IDN_BLOCK, {
                            productName: Config.productName,
                        }),
                        btmContent: ' ',
                        button: BUTTON.GOT_IT,
                        errorCode: null,
                    });
                    break;
                case ErrorCode.BLACKLIST_BLOCK:
                    this.setState({
                        title: t(keys.handleErrors.HEADER_MESSAGE_CANNOT_PROCEED),
                        topContent: t(keys.handleErrors.ASSISTANCE_MESSAGE, {
                            contactCenter: CALL_CENTER_NUMBER,
                        }),
                        btmContent: ' ',
                        button: BUTTON.EXIT,
                        errorCode: ErrorCode.BLACKLIST_BLOCK,
                    });
                    break;
                case ErrorCode.RISKSECURE_HIT:
                case ErrorCode.FRAUD_TRACK:
                case ErrorCode.CANNOT_ELIGIBILITY_CHECK:
                    this.setState({
                        title: t(keys.handleErrors.HEADER_HAS_PROBLEM),
                        topContent: t(keys.handleErrors.HAS_PROBLEM, {
                            contactCenter: CALL_CENTER_NUMBER,
                        }),
                        btmContent: ' ',
                        button: BUTTON.GOT_IT,
                        errorCode: null,
                    });
                    break;
                case ErrorCode.CELLPHONE_EXIST:
                    this.setState({
                        icon: inforIcon,
                        title: t(keys.handleErrors.HEADER_CELLPHONE_EXIST),
                        topContent: t(keys.handleErrors.CELLPHONE_EXIST),
                        btmContent: t(keys.handleErrors.CELLPHONE_EXIST_SUPPORT, {
                            contactCenter: CALL_CENTER_NUMBER,
                        }),
                        hasChangePhoneNumber: true,
                        button: BUTTON.EXIT,
                        errorCode: null,
                    });
                    break;
                case ErrorCode.APPLY_REACH_LIMIT:
                    this.setState({
                        title: t(keys.handleErrors.HEADER_APPLY_REACH_LIMIT),
                        topContent: t(keys.handleErrors.APPLY_REACH_LIMIT),
                        btmContent: ' ',
                        errorCode: null,
                    });
                    break;
                case ErrorCode.TOKEN_EXPIRED:
                    this.setState({
                        title: t(
                            keys.handleErrors.HEADER_MESSAGE_CANNOT_PROCEED,
                        ),
                        topContent: 'Sorry, we have encountered some issues while processing your application!',
                        btmContent: ' ',
                        errorCode: ErrorCode.TOKEN_EXPIRED,
                    });
                    break;
                case ErrorCode.LOAN_DELETED:
                    this.setState({
                        title: t(
                            keys.handleErrors.HEADER_MESSAGE_CANNOT_PROCEED,
                        ),
                        topContent: 'Sorry, we have encountered some issues while processing your application!',
                        btmContent: ' ',
                        errorCode: ErrorCode.LOAN_DELETED,
                    });
                    break;
                case ErrorCode.LOAN_DUPLICATED:
                    this.setState({
                        title: t(
                            keys.handleErrors.HEADER_MESSAGE_CANNOT_PROCEED,
                        ),
                        topContent: 'Your application has been created previously in another browser tab session. Please continue filling in your loan information in there.',
                        btmContent: ' ',
                        errorCode: ErrorCode.LOAN_DUPLICATED,
                    });
                    break;
                case ErrorCode.CONNECTIVITY_ERROR:
                    this.setState({
                        title: t(
                            keys.handleErrors.HEADER_MESSAGE_CANNOT_PROCEED,
                        ),
                        topContent: 'Sorry, we have encountered some issues while processing your application!',
                        btmContent: t(keys.handleErrors.CONNECTIVITY_ERROR_BOTTOM),
                        errorCode: ErrorCode.CONNECTIVITY_ERROR,
                    });
                    break;
                case ErrorCode.LINK_RETRIES_EXCEED:
                    this.setState({
                        title: 'You have reached the maximum number of attempts',
                        topContent: <p>Instead send us your bank statements to <span className="text-link mb-0">loan@tymebank.co.za.</span></p>,
                        btmContent: ' ',
                        errorCode: ErrorCode.LINK_RETRIES_EXCEED,
                    });
                    break;
                case ErrorCode.LINK_EXPIRED:
                    this.setState({
                        title: 'Unfortunately your loan application expired due to us not receiving your bank statements on time',
                        topContent: 'Please re-apply for a personal loan or contact us on 0860 999 119 to find out more.',
                        btmContent: ' ',
                        errorCode: ErrorCode.LINK_EXPIRED,
                    });
                    break;
                case ErrorCode.LINK_INVALID_STATE:
                    this.setState({
                        title: 'It seems that you already have submitted your bank statements',
                        topContent: 'Contact us on 0860 999 119 to find out more.',
                        btmContent: ' ',
                        errorCode: ErrorCode.LINK_INVALID_STATE,
                    });
                    break;
                case ErrorCode.LINK_IVALID_UPLOAD:
                    this.setState({
                        title: 'Sorry, we have encountered an issue while processing your request',
                        topContent: 'Contact us on 0860 999 119 to find out more.',
                        btmContent: ' ',
                        errorCode: ErrorCode.LINK_IVALID_UPLOAD,
                    });
                    break;
                case ErrorCode.EXPIRED_INVITATION:
                    this.setState({
                        button: BUTTON.GOT_IT,
                        title: t(keys.handleErrors.EXPIRED_INVITATION_HEADER),
                        topContent: t(keys.handleErrors.EXPIRED_INVITATION_CONTENT),
                        btmContent: ' ',
                        errorCode: ErrorCode.EXPIRED_INVITATION,
                    });
                    break;
                case ErrorCode.CELLPHONE_DONT_MATCH:
                    this.setState({
                        icon: inforIcon,
                        button: BUTTON.GOT_IT,
                        title: 'Please use the Tymebank registered cellphone',
                        topContent: (
                            <React.Fragment>
                                <p>We detect that you have already the Tymebank account and we suggest that you should use the same cellphone number as the one you registered with us.</p>
                                <p>Or you can update the cellphone number with Tymebank then come back here to continue with the Personal Loan process.</p>
                            </React.Fragment>
                        ),
                        btmContent: 'Contact us at 0860 999 119 if you need assistance.',
                        movetoChangePhone: true,
                    });
                    break;
                case ErrorCode.CELLPHONE_RESUME_DONT_MATCH:
                    this.setState({
                        icon: inforIcon,
                        button: 'Try again',
                        title: 'Unrecognized cellphone number',
                        topContent: 'You\'ll need to use the cellphone number linked to your loan application to continue.',
                        btmContent: '',
                        movetoChangePhone: true,
                    });
                    break;
                case ErrorCode.NONE_ACTIVATED_GPROFILE:
                    this.setState({
                        icon: inforIcon,
                        title: 'Activate you profile',
                        topContent: (
                            <React.Fragment>
                                <p>We created a profile for you after you applied for your personal loan.</p>
                                <p>You must activate your profile if you’d like to apply for another one. We’ll send you an email with instructions.</p>
                            </React.Fragment>
                        ),
                        btmContent: ' ',
                        button: BUTTON.EXIT,
                        isExist2DaysLoan: true,
                    });
                    break;
                case ErrorCode.MANDATE_TECHNICAL_ERROR:
                case ErrorCode.MANDATE_BUSSINES_ERROR:
                    this.handleGenericError(t, errorCode);
                    break;
                case ErrorCode.FACIAL_NO_FOTO_ERROR:
                    SegmentBusTrackFacials.segment2100FacialVerificationSaIDErrorNoHanisPhotoData();
                    this.setState({
                        title: 'There’s an issue with your photo at Home Affairs',
                        topContent: (
                            <React.Fragment>
                                <p>We weren’t able to reference your photo from Home Affairs.</p>
                                <p>You need to go to Home Affairs to update your photo.</p>
                            </React.Fragment>
                        ),
                        btmContent: ' ',
                        button: BUTTON.EXIT,
                    });
                    break;
                default:
                    this.handleGenericError(t, errorCode, onlyCloseModal);
            }
        } else {
            this.handleGenericError(t, ErrorCode.CONNECTIVITY_ERROR, onlyCloseModal);
        }
    }

    handleGenericError = (t, errorCode, onlyCloseModal) => {
        this.setState({
            title: t(
                keys.handleErrors.HEADER_MESSAGE_SOMETHING_WRONG,
            ),
            topContent: t(
                keys.handleErrors
                    .CONTENT_MESSAGE_TECHNICAL_DIFFICULTY,
            ),
            btmContent: t(
                keys.handleErrors.CONTENT_MESSAGE_CONTACT_CENTRE,
            ),
            errorCode,
            hasChangePhoneNumber: false,
            isExist2DaysLoan: false,
            onlyCloseModal,
        });
    };

    initialState = {
        icon: errorIcon,
        title: '',
        topContent: '',
        btmContent: '',
        errorCode: '',
        button: BUTTON.OK,
        redirectToHomepage: false,
        hasChangePhoneNumber: false,
        isExist2DaysLoan: false,
        onlyCloseModal: false,
        loanApplicationNumber: null,
        movetoChangePhone: false,
    };

    hideModal = () => {
        this.props.hideModalHandleErrorApi();
        setTimeout(() => this.setState({ ...this.initialState }), 200);

        if (this.state.onlyCloseModal || this.state.loanApplicationNumber) {
            return;
        }

        ScreenBusUtils.goHome();
    };

    handleQuit = () => {
        this.props.hideModalHandleErrorApi();

        // we need optiomize source
        setTimeout(() => this.setState(this.initialState), 200);
        ScreenBusUtils.goHome();
    }

    changeCellphoneNumber = () => {
        this.props.hideModalHandleErrorApi();
        setTimeout(() => this.setState({ ...this.initialState }), 200);

        ScreenBusUtils.gotoScreenPhone();
    }

    render() {
        const { t } = this.props;
        const {
            title,
            topContent,
            btmContent,
            errorCode,
            // isUpload,
            button,
            hasChangePhoneNumber,
            icon,
            onlyCloseModal,
            loanApplicationNumber,
            movetoChangePhone,
            isExist2DaysLoan,
        } = this.state;

        return (
            <Modal
                isOpen={this.props.showModal}
            >
                <ModalHeader>
                    <img className="icon" src={icon || errorIcon} alt="errorIcon" width="48" height="48" />
                    <span className="font-weight-medium">{title}</span>
                    <a onClick={this.hideModal} className="close-btn" aria-hidden="true">
                        <img src={closeX} alt="icon" />
                    </a>
                </ModalHeader>

                <ModalBody>
                    <div className="content modal-body__content">
                        {btmContent && (
                            <React.Fragment>
                                <p>{topContent}</p>
                                <p>
                                    {btmContent}
                                    {errorCode &&
                                        <span className={joinclass('d-block font-size-16', icon === inforIcon ? 'with-guilde' : 'with-errors')}>
                                            Error code: {errorCode}
                                        </span>
                                    }
                                </p>
                            </React.Fragment>
                        )}
                        {!btmContent && <p>{topContent}</p>}
                    </div>

                    <WrapAction className="modal-buttons flex-column d-flex d-md-block flex-column-reverse">

                        {!isExist2DaysLoan ?
                            <React.Fragment>
                                {onlyCloseModal &&
                                    <PLButton
                                        primary={false}
                                        onClick={this.handleQuit}>
                                        I'll do it later
                                    </PLButton>
                                }

                                <PLButton
                                    primary={!hasChangePhoneNumber}
                                    onClick={() => {
                                        if (movetoChangePhone) {
                                            this.changeCellphoneNumber();
                                            return;
                                        }

                                        if (loanApplicationNumber) {
                                            ScreenBusUtils.saveDeclineStatus(ErrorCode.EXISTING_INVITATION); // keep code
                                            ScreenBusUtils.verifyResumeLoanApplicationBySaID();
                                        }
                                        this.hideModal();
                                    }}>
                                    {button}
                                </PLButton>

                                {hasChangePhoneNumber && (
                                    <PLButton
                                        className="btn btn-yellow"
                                        onClick={this.changeCellphoneNumber}>
                                        {t(keys.handleErrors.CHANGE_PHONE_NUMBER_BUTTON)}
                                    </PLButton>
                                )}
                            </React.Fragment> :
                            <React.Fragment>
                                <PLButton
                                    primary={false}
                                    onClick={this.hideModal}
                                >
                                    Exit
                                </PLButton>

                                <PLButton
                                    className="btn btn-yellow"
                                    onClick={e => {
                                        if (typeof window.open === 'function') {
                                            window.open(Config.profileUrlActivation, '_blank', 'noopener,noreferrer');
                                            e.preventDefault();
                                            e.stopPropagation();
                                            return false;
                                          }
                                          return true;
                                    }}
                                >
                                    Activate profile
                                </PLButton>
                            </React.Fragment>
                        }
                    </WrapAction>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    showModal: state.handleErrorApi.showHandleErrorModal,
    errors: state.handleErrorApi.errors,
});

const mapDispatchToProps = {
    hideModalHandleErrorApi,
};

export default translate()(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(HandleErrorModal),
);
