import React from "react";
import { SegmentBusTrackCode } from "../../segment-bus/SegmentBusTrackCode";

export default class ErrorBoundary extends React.Component {
    // Constructor for initializing Variables etc in a state
    // Just similar to initial line of useState if you are familiar
    // with Functional Components
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    // This method is called if any error is encountered
    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and
        // re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    // This will render this component wherever called
    render() {
        if (this.state.errorInfo) {
            const error = {
                dev_error_code: this.props.componentName || (this.state.error && this.state.error.toString()),
                dev_error_message: this.state.errorInfo,
                dev_input_data_captured: 'react component rendered',
            }
            SegmentBusTrackCode.segment2200LoanPageErrorDisplayedData(error);

            // Error path
            return (
                <div>
                    <h1>{this.props.componentName}: Something went wrong.</h1>;
                </div>
            );
        }

        // Normally, just render children, i.e. in
        // case no error is Found
        return this.props.children;
    }
}