import React, { useCallback, useState } from 'react';

import { CONTRACT_EMPLOYEE } from '../../constants';
import { scrollElementSmooth } from '../../utils/commonUtils';
import { ValidatorUtils } from '../../utils/yupUtils';
import { ScreenBusUtils } from '../../hooks/useEventBus';

import BackButton from '../Common/BackButton';
import PLDiv from '../Common/PLDiv';
import { WrapAction } from '../Common/ActionButtons';
import PLButton from '../Common/PLButton';
import ButtonListSelectionTick from '../Common/ButtonListSelectionTick';

import InputCompany from './InputCompany';

const UserInfoTabTwo = ({
    userData: {
        emailValue,
        selectedRaceName,
        selectedEmploymentStatus,
        company,
        workingMonth,
        workingYear,
    },
    yearGap,
    listEmployment,
    isEditMode,
    handleBack = () => { },
    handleNextSection = () => { },
}) => {
    const d = new Date();
    const [formData, setFormData] = useState({
        selectedEmploymentStatus,
        company,
        workingMonth: workingMonth || d.getMonth() + 1,
        workingYear: workingYear || d.getFullYear(),
    });
    const [errors, setErrors] = useState({});

    const refsInput = React.useRef();
    const hasContract = CONTRACT_EMPLOYEE.includes(formData.selectedEmploymentStatus);

    const handleNextSelection = useCallback(() => {
        let errorMessage = ValidatorUtils.validateOptionEmpty({
            value: formData.selectedEmploymentStatus,
        });

        if (errorMessage) {
            setErrors({
                selectedEmploymentStatus: errorMessage,
            });
            return;
        }

        if (hasContract) {
            errorMessage = ValidatorUtils.validateEmpty({
                value: formData.company,
                emptyMessage: 'Enter a company name.',
            });

            if (errorMessage) {
                setErrors({
                    company: errorMessage,
                });

                refsInput.current.focus();
                setTimeout(() => {
                    scrollElementSmooth(refsInput.current, 'center');
                }, 250);

                return;
            }
        }

        handleNextSection(formData);
    }, [formData]);

    return (
        <React.Fragment>
            <BackButton onClick={handleBack}>
                <PLDiv
                    mobileContent={
                        <React.Fragment>
                            Email address: {emailValue}<br />
                            Race: {selectedRaceName}
                        </React.Fragment>
                    }
                    webContent={
                        <React.Fragment>Email address: {emailValue}; Race: {selectedRaceName};</React.Fragment>
                    }
                />
            </BackButton>

            <ButtonListSelectionTick
                label="What is your current employment status?"
                flexClassName="justify-content-start"
                selectedValue={formData.selectedEmploymentStatus}
                listData={listEmployment}
                btnGroup="user-status"
                errorMessage={errors.selectedEmploymentStatus}
                handleChangeSelection={(selectedValue) => {
                    setFormData({
                        ...formData,
                        selectedEmploymentStatus: selectedValue,
                    });
                    setErrors({
                        ...errors,
                        selectedEmploymentStatus: '',
                    });
                }}
                handleNext={handleNextSelection}
            />

            {hasContract &&
                <InputCompany
                    {...formData}
                    yearGap={yearGap}
                    errorMessage={errors.company}
                    getInputRef={e => { refsInput.current = e; }}
                    setFormData={(value, name, resetMonth) => {
                        if (name === 'company') {
                            setErrors({
                                ...errors,
                                company: '',
                            });
                        }
                        setFormData({
                            ...formData,
                            workingMonth: resetMonth ? 1 : formData.workingMonth,
                            [name]: value,
                        });
                    }}
                />
            }

            <WrapAction className="buttons my-4 flex-column-reverse d-flex d-md-block">
                {isEditMode &&
                    <PLButton
                        primary={false}
                        onClick={() => ScreenBusUtils.gotoScreenConfirmation()}>
                        Cancel
                    </PLButton>
                }

                <PLButton onClick={handleNextSelection}>
                    {isEditMode ? 'Save' : 'Next'}
                </PLButton>
            </WrapAction>
        </React.Fragment>
    );
};

export default UserInfoTabTwo;
