import React, { useState } from 'react';

import PLAutoComplete from '../Common/PLAutoComplete';
import {
    canSearchText,
    isStartsWithSelection,
} from './TruidUtils';

const TruidCityComplete = ({
    listData = [],
    formData = {},
    handleSelection,
    isOpen,
}) => {
    const [show, setShow] = useState(isOpen);
    const listFilter = filterListData();

    function filterListData() {
        const text = `${formData.city}`.trim();
        if (!show || !canSearchText(text)) {
            return [];
        }

        const { cities = [] } = formData;
        if (cities.length) {
            return cities.filter(item => isStartsWithSelection(item.city, text));
        }

        return listData.filter(item => isStartsWithSelection(item.city, text));
    }

    return (
        <PLAutoComplete
            className="autocomplete-base-input"
            classNameInput="form-control-address"
            nameText="city"
            nameValue="city"
            noDataMsg=""
            text={formData.city}
            maxLength={50}
            showing={show}
            disabledSearchIcon
            listSuggest={listFilter}
            formatDropDownItem={item => (
                <span className="d-flex flex-column">
                    <span className="font-weight-400 font-size-18">{item.city}</span>
                    <span>{item.province}</span>
                </span>
            )}

            setText={(newValue) => {
                if (newValue.trim() !== formData.city.trim()) {
                    handleSelection({ freeText: newValue.trim() });
                    setShow(true);
                }
            }}

            handleSelection={(item) => {
                handleSelection({ item });
                setShow(false);
            }}

            handleOutSideClick={() => setShow(false)}
        />
    );
};

export default TruidCityComplete;
