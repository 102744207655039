import React from 'react';
import { useModalPopupContext } from '../../context/ModalPopupContext';
import useEventBus, { EventBusName, ScreenBusUtils, TruIDBusUtils } from '../../hooks/useEventBus';

import ConfirmFlowTruIDOrSprintHive from './ConfirmFlowTruidOrSprintHive';
import { TruidAffordabilityTimeout } from './TruidConfirmChangeAddress';

function useHandleEventTruID() {
    const {
        showModal,
        showErrorModal,
        hideModal,
    } = useModalPopupContext();

    useEventBus(EventBusName.TRUID_NOTIFY_ACTION, ({
        error, cancel, timeout,
    }) => {
        if (error) {
            showErrorTruID();
            return;
        }
        if (cancel) {
            showCancelTruID();
            return;
        }
        if (timeout) {
            showTimeoutTruid();
        }
    });

    function showTimeoutTruid() {
        showModal({
            modalContent: <TruidAffordabilityTimeout />,
            centerAction: true,
            showCancel: false,
            escOnClose: false,
            handleXClose: () => ScreenBusUtils.goHome(),
            handleOK: () => ScreenBusUtils.goHome(),
            okTitle: 'Got it',
        });
    }

    function showErrorTruID() {
        showErrorModal({
            modalTitle: 'Looks like there was a technical issue',
            modalContent: (
                <React.Fragment>
                    <div>You can choose to rather email us your bank statements.</div>
                    <div>Or you can restart the truID process.</div>
                </React.Fragment>
            ),
            showCancel: true,
            escOnClose: false,
            showXClose: false,
            cancelTitle: 'Restart truID',
            okTitle: 'Email bank statements',
            handleCancel: () => TruIDBusUtils.refreshURLTruIDScreen(),
            handleOK: () => {
                ScreenBusUtils.gotoScreenIntroSprintHive();
                hideModal();
            },
        });
    }

    function showCancelTruID() {
        showModal({
            modalContent: <ConfirmFlowTruIDOrSprintHive />,
            centerAction: true,
            showCancel: true,
            escOnClose: false,
            showXClose: false,
            cancelTitle: 'Retry truID',
            okTitle: 'Email bank statements',
            handleCancel: () => TruIDBusUtils.refreshURLTruIDScreen(),
            handleOK: () => {
                ScreenBusUtils.gotoScreenIntroSprintHive();
                hideModal();
            },
        });
    }
}

export default useHandleEventTruID;
