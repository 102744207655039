import { useEffect, useState } from 'react';

import { INCONME_TYPE_VERIFIER } from '../../constants';
import useEventBus, { EventBusName, ScreenBusUtils, TruIDBusUtils } from '../../hooks/useEventBus';

// listen refresh Truid Data
function useRefreshURLTruID({
    offersState,
    fetchDecisionResults,
    loanApplicationNumber,
}) {
    const [flagScreen, setFlagScreen] = useState(false);

    useEventBus(EventBusName.TRUID_NOTIFY_REFRESH, () => {
        fetchDecisionResults({ loanApplicationNumber });
        setFlagScreen(true);
        ScreenBusUtils.hideModalCommon();
    });

    useEffect(() => {
        if (flagScreen) {
            setFlagScreen(false);
            const { inviteUrl, incomeVerifier } = offersState || {};
            if (!inviteUrl || incomeVerifier === INCONME_TYPE_VERIFIER.SPRINT_HIVE) {
                ScreenBusUtils.gotoScreenOffers();
                return;
            }

            ScreenBusUtils.gotoScreenTruID();
            TruIDBusUtils.refreshTruIDIFrame();
        }
    }, [offersState]);
}

export default useRefreshURLTruID;
