import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import _ from 'lodash';
import { G_PROFILE_INCOME_TYPE, TRUID_ADDRESS_MISSING, TRUID_SALARY_DATE_MISSING, truidResumeStatus } from '../truidConstants';
import { DecisioningResult } from '../constants';
import { ScreenBusUtils } from '../hooks/useEventBus';

export const getProvider = () => {
  const host = window.location.host.toLowerCase();

  let provider = 'TYMEBANK';
  if (host.startsWith('tfg') || host.startsWith('localhost')) {
    provider = 'TFG';
  }
  return provider;
};

export const scrollElementSmooth = (element, block = 'start') => {
  if (!element) {
    return Promise.resolve();
  }
  return scrollIntoView(element, {
    // scrollMode: 'if-needed',
    block,
    inline: 'nearest',
  });
};

/** Clone scrollElementSmooth. don't want refactor */
export const scrollElementIfNeeded = (element, block = 'center') => {
  if (!element) {
    return Promise.resolve();
  }
  return scrollIntoView(element, {
    scrollMode: 'if-needed',
    block,
    inline: 'nearest',
  });
};

export const scrollTopSmooth = () => {
  const header = document.querySelector('.pl-container-fluid');
  return scrollElementSmooth(header);
};

export const calulatorLoanPackage = (terms, loanValue, product) => {
  /*
  Initiation fee (VAT inclusive):
  If P < 1000, initiation fee = 170.78
  If P >= 1000, initiation fee = (165 + ((P - 1000) x 0.1)) x 1.15
  → Based on the example, initiation fee = (165 + ((5000 - 1000) x 0.1)) x 1.15 = 649.75
  */
  // Capped at R1207.5 (VAT inclusive)
  let initiationFee = loanValue < 1000 ? 170.78 : (165 + ((loanValue - 1000) * 0.1)) * 1.15 * 0.9;
  if (initiationFee > 1207.5) {
    initiationFee = 1207.5;
  }

  /**
  Loan amount (Loan principle): P
  Loan term (number of installment): n
  Interest rate: R
  Total loan outstanding balance: AP
  */
  // r = round((R / 12),2) / 100
  const r = Number(product.maxInterestRate / 12).toFixed(2) / 100;
  // eslint-disable-next-line no-restricted-properties
  const powR = Math.pow((1 + r), terms);

  // (EMI) =  (P+Initiation fee) x (r x (1 + r)^n / ((1 + r)^n - 1))
  // eslint-disable-next-line no-mixed-operators
  const EMI = Number((loanValue + initiationFee) * (r * powR / ((powR - 1)))).toFixed(2);

  // AP = (P+ Initiation fee)/2
  const AP = (loanValue + initiationFee) / 2;

  // Total interest = (EMI x n) - (P+ Initiation fee)
  // const totalInterest = (EMI * terms) - (loanValue + initiationFee);

  // Service fee (VAT inclusive) = 69
  const serviceFree = 60;

  /*
  TFG insurance premium (Estimated monthly loanValue for permanent employment) & round to 2 decimals for final result)= 0.396 x (AP/100) x n
  → Based on the example, monthly premium = 0.396 x (2824.88/100) x 6 = 67.12
  */
  const tfgInsurance = Number(0.396 * (AP / 100) * terms);
  const monthRepay = Number(EMI) + Number(serviceFree) + Number(tfgInsurance / terms);
  // eslint-disable-next-line no-mixed-operators
  const totalInterestAndFee = Number(monthRepay).toFixed(2) * terms - Number(loanValue);
  const monRepay = Number(monthRepay).toFixed(2);

  return {
    monthRepay: monRepay,
    totalInterestAndFee: Number(totalInterestAndFee).toFixed(2),
    interestRate: product.maxInterestRate,
    initiationFee: Number(initiationFee).toFixed(2),
    insurance: tfgInsurance,
    serviceFee: serviceFree,
    totalRepay: Number(terms * monRepay).toFixed(2),
  };
};

export const isMissingAddressTruID = code => code === TRUID_ADDRESS_MISSING;
export const isMissingSalaryDate = code => code === TRUID_SALARY_DATE_MISSING;
export const isGProfileIncomeType = code => code === G_PROFILE_INCOME_TYPE;

export const isTruIDCanTryAgain = code => Boolean(truidResumeStatus[code].buttonCancel);

export const handleDeclineSpecialCode = ({
  declinceCode,
  loanApplicationStatus,
  handleMissingAddress,
  handleMissingSalary,
  handleMissing,
  handleGProfileType,
} = {}) => {
  const isAddress = isMissingAddressTruID(declinceCode);
  const isSalaryDate = isMissingSalaryDate(declinceCode);
  const isGProfileType = isGProfileIncomeType(declinceCode);

  if (!isAddress && !isSalaryDate && !isGProfileType) {
    return false;
  }

  const isReadyMissing = !loanApplicationStatus ||
    loanApplicationStatus === DecisioningResult.loanApplicationStatus.CONDITIONALLY_APPROVED_COLLECTION_RECEIVED;

  if ((isAddress || isSalaryDate) && handleMissing && isReadyMissing) {
    return handleMissing;
  }

  if (isAddress && isReadyMissing) {
    return handleMissingAddress;
  }

  if (isSalaryDate && isReadyMissing) {
    return handleMissingSalary;
  }

  if (isGProfileType) {
    return handleGProfileType;
  }

  return undefined;
};

export function execShowMissingFormWhenDeclineCode(declinceCode) {
  return new Promise(resolve => {
    const funcHandle = handleDeclineSpecialCode({
      declinceCode,
      handleMissingSalary: () => ScreenBusUtils.showTruIDFormSalaryDate(),
      handleMissingAddress: () => ScreenBusUtils.showTruIDFormAddress(),
    });

    const hasFunc = Boolean(funcHandle);
    if (hasFunc) {
      funcHandle();
    }
    resolve(hasFunc);
  });
}

export const isEditMode = ({ match = {} }) => _.get(match, 'params.mode') === 'edit';

function buildDurationMinisecond() {
  return (new Array(20).fill(1)).map((__, index) => `${index * 1}s`);
}
export const durationTimes = buildDurationMinisecond();

export function countSetTimeoutCalls() {
  return setTimeout.mock.calls.filter(([fn, t]) => (
    t !== 0 ||
    !String(fn).includes('_flushCallback')
  ));
}

export const calculatorSlider = (min, max) => {
  const range = (max - min) % 1000;
  const size = Math.floor((max - min) / 1000) + 1;
  return range > 0 ? size + 1 : size;
};

export const matchingSlider = ({
  upMin, upMax, sliderStep, sliderSize,
}) => {
  // eslint-disable-next-line no-mixed-operators
  const value = upMax - (sliderSize - sliderStep) * 1000;
  return value < upMin ? upMin : value;
};
