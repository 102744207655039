/* eslint-disable default-case */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { $emit } from 'event-bus-e2z';
import { EventBusName, ScreenBusUtils } from '../../hooks/useEventBus';

import { ErrorCode } from '../../constants';
import { TRUID_ADDRESS_MISSING, TRUID_SALARY_DATE_MISSING } from '../../truidConstants';

import { useSearchAddressByPlaceId, useUpdateAddressInformation, useUpdateSalaryDate } from '../../services/useTruidService';
import { findCityBySuburb, findCityItem, findProvinceItem, upperFirst } from './TruidUtils';

export const useParseAddressDetails = ({
    placeId,
    description,
    saAdministrative,
}) => {
    const [infoForm, setInfoForm] = useState();
    const {
        administrativeData,
        citySugestions = [],
        suburbSugestions,
    } = saAdministrative;

    const {
        data, loading, refetchParam, cancelRequest,
    } = useSearchAddressByPlaceId({
        placeId,
    });

    useEffect(() => {
        if (placeId) {
            cancelRequest();
            refetchParam({
                newParams: {
                    place_id: placeId,
                },
                mergeParams: true,
            });
        }
    }, [placeId]);

    useEffect(() => {
        if (data && !loading) {
            const addressComponents = _.get(data, 'result.address_components', []);
            let address = '';
            let route = '';
            let zipCode = '';
            let city = '';
            let province = '';
            let sublocalityLevel = '';
            let streetNumber = '';

            for (const component of addressComponents) {
                const { long_name, types } = component;
                switch (types[0]) {
                    // address
                    case 'street_number': {
                        streetNumber = long_name;
                        break;
                    }

                    case 'route': {
                        address += long_name;
                        route = long_name;
                        break;
                    }

                    // maybe suburb
                    case 'sublocality_level_1': {
                        sublocalityLevel = `${long_name}`.trim();
                        break;
                    }

                    case 'sublocality_level_2': {
                        sublocalityLevel = !sublocalityLevel ? long_name : sublocalityLevel;
                        break;
                    }

                    // city
                    case 'locality': {
                        city = long_name;
                        break;
                    }

                    case 'postal_code': {
                        zipCode = long_name;
                        break;
                    }

                    case 'administrative_area_level_1': {
                        province = long_name;
                        break;
                    }
                }
            }

            // split route
            let infoRoad = ((description.split(route) || [])[0] || '').trim();
            infoRoad = infoRoad.includes(streetNumber) ? infoRoad : `${streetNumber} ${infoRoad}`.trim();

            setInfoForm({
                ...infoForm,
                address: `${infoRoad} ${address.trim()}`.trim(),
                suburb: upperFirst(sublocalityLevel),
                city: findCityItem(upperFirst(city), citySugestions) || upperFirst(city),
                province: findProvinceItem(upperFirst(province)) || '',
                zipCode,
                cities: findCityBySuburb({
                    suburb: sublocalityLevel,
                    suburbSugestions,
                    administrativeData,
                }),

                // clear info
                placeId: '',
                description: '',
                active: true,
            });
        }
    }, [data, loading]);

    return { infoForm };
};

export const useSaveAddress = (formData, loanApplicationNumber, stepUpToken) => {
    const {
        data, onPost: postChange, loading: isFetch, errors,
    } = useUpdateAddressInformation(
        {
            data: {
                ...formData,
                loanApplicationNumber,
            },
            stepUpToken,
        },
    );

    // handler error
    useEffect(() => {
        if (isFetch) {
            return;
        }

        // show error
        if (errors) {
            $emit(EventBusName.NOTIFY_ERROR, {
                errors: [{ errorCode: ErrorCode.ADDRESS_ERROR_CODE }],
                onlyCloseModal: true,
            });
            return;
        }

        if (data) {
            ScreenBusUtils.fetchAffordabilityAfterUpdateMissing(TRUID_ADDRESS_MISSING);
            ScreenBusUtils.hideModalCommon();
            ScreenBusUtils.clearDeclineStatus();
        }
    }, [data, isFetch, errors]);

    return { postChange, isFetch };
};

export const useSaveSalaryDate = (formData, loanApplicationNumber, stepUpToken) => {
    const {
        data, onPost: postChange, loading: isFetch, errors,
    } = useUpdateSalaryDate(
        {
            data: {
                ...formData,
                loanApplicationNumber,
            },
            stepUpToken,
        },
    );

    // handler error
    useEffect(() => {
        if (isFetch) {
            return;
        }

        // show error
        if (errors) {
            $emit(EventBusName.NOTIFY_ERROR, {
                errors: [{ errorCode: ErrorCode.ADDRESS_ERROR_CODE }],
                onlyCloseModal: true,
            });
            return;
        }

        if (data) {
            ScreenBusUtils.fetchAffordabilityAfterUpdateMissing(TRUID_SALARY_DATE_MISSING);
            ScreenBusUtils.hideModalCommon();
            ScreenBusUtils.clearDeclineStatus();
        }
    }, [data, isFetch, errors]);

    return { postChange, isFetch };
};
