import { of, timer } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, exhaustMap, filter, map, switchMap, takeUntil } from 'rxjs/operators';

import Config from '../config';
import apiService from '../services/apiService';

import { FETCH_LOAN_DETAIL, FETCH_LOAN_DETAIL_SUCCESS, PUT_SELECTED_OFFER } from '../store/actionTypes';
import {
    fetchLoanDetailSuccess,
    fetchLoanDetailFailed,
    putSelectedOfferSuccess,
    fetchContractInfo,
} from '../actions';

import ErrorUtils from '../utils/errorUtils';
import { ScreenBusUtils } from '../hooks/useEventBus';

export function fetchEpicLoanDetail(payload, timeCounter, state$) {
    const {
        stepUpToken, loanApplicationNumber, initiationFeePaymentMethod, retryIfError = false,
    } = payload;

    return apiService
        .getLoanDetail(stepUpToken, loanApplicationNumber, initiationFeePaymentMethod)
        .pipe(
            map(res => fetchLoanDetailSuccess(res)),
            catchError(error => {
                // delay make sure api update
                if (retryIfError && state$.value.loanDetail.retryCount < timeCounter && error.status === 400) {
                    return of(fetchLoanDetailFailed({ keepLoading: true }));
                }
                return of(fetchLoanDetailSuccess({}), ErrorUtils.getFuncApiAction(error));
            }),
        );
}

export function loanDetailEpic(action$, state$) {
    return action$.pipe(
        ofType(FETCH_LOAN_DETAIL),
        switchMap(({ payload }) => {
            const { timeDelay, timeCounter } = Config.loanOfferTiming;
            return timer(0, timeDelay)
                .pipe(
                    takeUntil(action$.pipe(filter(action => action.type === FETCH_LOAN_DETAIL_SUCCESS))),
                    exhaustMap(() => fetchEpicLoanDetail(payload, timeCounter, state$)),
                );
        }));
}

export function selectLoanOfferEpic(action$) {
    return action$.pipe(
        ofType(PUT_SELECTED_OFFER),
        switchMap(({ payload }) => {
            const { stepUpToken, loanApplicationNumber, selectedOfferOptionId } = payload;
            return apiService
                .putSelectedOffer(stepUpToken, loanApplicationNumber, selectedOfferOptionId)
                .pipe(
                    switchMap(res => {
                        return of(
                            putSelectedOfferSuccess(res),
                            fetchContractInfo({
                                stepUpToken,
                                loanApplicationNumber,
                            }),
                            ScreenBusUtils.gotoScreenLoanAgreement(),
                        );
                    }),
                    catchError(error => ErrorUtils.getApiAction(error)),
                );
        }));
}

export default combineEpics(loanDetailEpic, selectLoanOfferEpic);
