import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { saveStepsInput, verifyLoanApplicationInElibility } from '../actions';

import PLButton from '../components/Common/PLButton';
import ActionSteps from '../components/Common/ActionSteps';
import LabelLoanApplication from '../components/Common/LabelLoanApplication';
import { FlexColumnCenter, FlexRowCenter } from '../components/Common/FlexCenter';
import { WrapAction } from '../components/Common/ActionButtons';

import useFacialPoolingResults from '../components/Selfie/useFacialPoolingResults';
import useFacialHandleModal from '../components/Selfie/useFacialHandleModal';
import useFacialRefreshSession from '../components/Selfie/useFacialRefreshSession';

import {
    FacialBusUtils,
    ScreenBusUtils,
} from '../hooks/useEventBus';
import useNetworkState from '../hooks/useNetworkState';
import { SegmentBusTrackFacials } from '../segment-bus/SegmentBusTrackFacials';
import { useGetFacialSDK } from '../services/useFacialSDKService';
import { introSelfieStepData } from '../assets/data/selfie-step-data';

// import data from '../components/Selfie/_sdk.json';

const readyFacetecSDKStatus = [-1, 1];

export async function onSelfieComplete(latestSessionResult, isSuccess) {
    if (isSuccess && latestSessionResult) {
        FacialBusUtils.refreshFacialResults();
        SegmentBusTrackFacials.segment2100FacialVerificationWaitingResultData();
    }
}

const IntroSelfie = ({
    loanDetail,
    stepsInputData,
    customerInfo,
    stepUpToken,
    loanSubmission,
    verifyLoanApplicationData,
    // eslint-disable-next-line no-shadow
    saveStepsInput,
    // eslint-disable-next-line no-shadow
    verifyLoanApplicationInElibility,
}) => {
    const [readyFacetec, setReadyFacetec] = useState(false);
    const [isPostback, setPostback] = useState(false);
    const [statusErrorSDK, setStatusErrorSDK] = useState(-1);

    const loanApplicationNumber = _.get(loanSubmission, 'loanApplicationNumber') ||
        _.get(verifyLoanApplicationData, 'loanApplicationNumber');

    const isOnline = useNetworkState();

    useFacialHandleModal({
        loanDetail,
        readyFacetec,
        onComplete: onSelfieComplete,
        intialFacetec,
    });

    useFacialPoolingResults({
        stepsInputData,
        stepUpToken,
        loanApplicationNumber,
        verifyLoanApplicationInElibility,
    });

    const {
        refetch: fetchSdk,
        errors: errorSdk,
        data,
    } = useGetFacialSDK({
        stepUpToken,
        phoneNumber: stepsInputData.phoneNumber || customerInfo.phone,
        said: stepsInputData.said || customerInfo.said,
    });

    const {
        onPost: fetchSession,
        errors: errorSession,
        facialInfoData,
    } = useFacialRefreshSession({
        isPostback,
        stepsInputData,
        customerInfo,
        loanApplicationNumber,
        stepUpToken,
        saveStepsInput,
    });

    function intialFacetec(isFirst) {
        return FacialBusUtils.initSDKFacial({ ...data, isFirst })
            .then(
                () => {
                    setReadyFacetec(true);
                    setStatusErrorSDK(-1);
                    return true;
                },
                sdkStatus => {
                    setReadyFacetec(false);
                    setStatusErrorSDK(sdkStatus);
                    return false;
                },
            );
    }

    useEffect(() => {
        ScreenBusUtils.showLoadingLazy();
        FacialBusUtils.setupSDKFacial();
        SegmentBusTrackFacials.segment2100FacialVerificationIntroData();

        // flag: duplicate segment tracking
        setPostback(true);
        fetchSdk(true);
        fetchSession(true);
    }, []);

    useEffect(() => {
        if (errorSdk || errorSession) {
            ScreenBusUtils.hideLoadingLazy(250);
            setPostback(false);

            // Remove duplicate segment tracking
            if (isPostback) {
                const contextErrSDK = _.get(errorSdk, 'response') || _.get(errorSdk, 'cause.message');
                const contextErrSession = _.get(errorSession, 'response') || _.get(errorSession, 'cause.message');

                FacialBusUtils.showErrorConfig(() => {
                    ScreenBusUtils.showLoading();
                    errorSdk && fetchSdk(true);
                    errorSession && fetchSession(true);
                    setPostback(true);
                }, contextErrSDK || contextErrSession);
            }
            return;
        }

        if (data && facialInfoData) {
            ScreenBusUtils.hideLoadingLazy(1000);
            setPostback(false);
        }
    }, [errorSdk, errorSession, data, facialInfoData]);

    useEffect(() => {
        if (data) {
            saveStepsInput({ facialSdkData: data });
            !readyFacetec && intialFacetec(true);
        }
    }, [data]);

    return (
        <div className="pl-container-highlight px-1">
            <div className="container d-flex flex-column mb-4">
                <LabelLoanApplication />

                <FlexColumnCenter>
                    <p className="mt-4 my-md-3 font-weight-500 font-size-20 font-size-md-40">
                        Let's take selfie
                    </p>

                    <p className="my-3 my-md-4 font-size-16 font-weight-500">Here are some tips:</p>
                </FlexColumnCenter>

                <ActionSteps
                    className="intro-selfie-steps mt-4 mt-md-5"
                    listSteps={introSelfieStepData}
                    noLinkLine
                    disabledPoint
                />
            </div>

            <div className="container">
                <FlexRowCenter>
                    <WrapAction className="mt-3 mb-5">
                        <PLButton
                            onClick={() => {
                                if (!readyFacetecSDKStatus.includes(statusErrorSDK)) {
                                    FacialBusUtils.showSDKError(statusErrorSDK, true);
                                    return;
                                }

                                if (!isOnline) {
                                    FacialBusUtils.showSDKError(2, true);
                                    return;
                                }

                                FacialBusUtils.askCamera();
                            }}
                        >
                            Take a selfie
                        </PLButton>
                    </WrapAction>
                </FlexRowCenter>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    loanDetail: _.get(state, 'loanDetail.loanDetail.loan') || {},
    customerInfo: _.get(state, 'loanContractInfo.loanContractInfo.customerInfo') || {},
    stepsInputData: state.stepsInputData,
    stepUpToken: state.otp.stepUpToken,
    verifyLoanApplicationData: state.verifyLoanApplicationData,
    loanSubmission: state.loanSubmission,
});

const mapDispatchToProps = {
    saveStepsInput,
    verifyLoanApplicationInElibility,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IntroSelfie);
