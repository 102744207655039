// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { scrollTopSmooth } from '../utils/commonUtils';
import { formatPageTitle, isHomePage, isSegmentPage } from '../utils/pageUtils';

import useEventBus, { EventBusName, ScreenBusUtils } from './useEventBus';
import useGAPageTracking from './useGAPageTracking';
import useSegmentAnalytics, { useSegmentTrackingEvents } from './useSegmentAnalytics';
import { useBeforePageUnload } from './useBeforePageLeave';

const useCommonInit = ({
  router,
  stepsInputData,
  loanApplicationNumber,
  hashedSAID,
  isLANResuming,
  pageName,
}) => {
  const [isStartPL, setStartPL] = useState(false);
  const { pathname, search } = router.location;

  const newPageName = isSegmentPage(pathname) ? 'Landing - Segment Campaign' : pageName;

  const prefixTitle = formatPageTitle(isLANResuming);
  const name = `${prefixTitle ? `${prefixTitle} | ` : ''}${newPageName}`;
  document.title = `${prefixTitle ? `${prefixTitle} - ` : ''}Personal Loan | ${newPageName}`;

  // mark accept consent
  useEventBus(EventBusName.NOTIFY_ACCEPT_CONSENT, () => setStartPL(true));

  // Google GA Page
  useGAPageTracking(router, isLANResuming);

  useSegmentAnalytics({
    router,
    loanApplicationNumber,
    pageName: name,
  });

  useSegmentTrackingEvents({ stepsInputData, loanApplicationNumber, hashedSAID });

  useBeforePageUnload(pathname);

  useEffect(() => {
    scrollTopSmooth();

    const isResume = search.startsWith('?loanApplication=');

    if (isHomePage(pathname) && isResume) {
      setStartPL(true);
      return;
    }

    if (isSegmentPage(pathname) && search && !isResume) {
      return;
    }

    if (isHomePage(pathname)) {
      setStartPL(false);
      return;
    }

    // access normal url
    if (!(isHomePage(pathname) || isSegmentPage(pathname))) {
      sessionStorage.clear();
    }

    if (!isStartPL || isSegmentPage(pathname)) {
      ScreenBusUtils.goHome();
    }
  }, [router.location.pathname]);
};

export default useCommonInit;

